import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box } from '@mui/material'
import StyledSwitch from '../common/StyledSwitch'

// Import Actions & Methods
import { getFixedOutlets, setFixedOutlets, setIsFixedOutletsOn } from '../../redux/actions/navActions'
import { clearFixedOutletsFromMap } from '../../redux/actions/mapActions'

const FixedOutletsControl = ({ dispatch, selectedRoute, isFixedOutletsOn }) => {
  useEffect(() => {
    return () => {
      dispatch( setIsFixedOutletsOn(false) )

      // Clear Fixed Outlets
      dispatch( setFixedOutlets([]) )
      dispatch( clearFixedOutletsFromMap() )
    }
  }, [ dispatch ])

  useEffect(() => {
    if(isFixedOutletsOn) {
      if(selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None') {
        // Load Fixed Outlets
        dispatch( getFixedOutlets({ route_id: selectedRoute?.id ?? '' }) )
      }

    } else {
      // Clear Fixed Outlets
      dispatch( setFixedOutlets([]) )
      dispatch( clearFixedOutletsFromMap() )
    }
  }, [ dispatch, isFixedOutletsOn, selectedRoute ])

  const _onFixedOutletsControlChange = (e, value) => {
    dispatch( setIsFixedOutletsOn(value) )
  }

  return (
    <Box>
      <StyledSwitch
        label='Fixed Outlets'
        checked={ isFixedOutletsOn }
        value={ isFixedOutletsOn }
        onChange={ _onFixedOutletsControlChange }
      />
    </Box>
  )
}

// Prop Types
FixedOutletsControl.propTypes = {
  selectedRoute: PropTypes.object,
  isFixedOutletsOn: PropTypes.bool,
  dispatch: PropTypes.func
}

FixedOutletsControl.defaultProps = {
  selectedRoute: null,
  isFixedOutletsOn: false,
  dispatch: () => null
}

const mapStateToProps = state => ({
  selectedRoute: state?.nav?.selectedRoute ?? null,
  isFixedOutletsOn: state?.nav?.isFixedOutletsOn ?? false,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(FixedOutletsControl)
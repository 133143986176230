import { NavActionTypes } from '../actions/actionTypes'
import { AllInclusive, AccessTime, Bolt, ThumbDownOffAlt, RemoveCircleOutline, HighlightOff } from '@mui/icons-material'

const initialState = {
  isNavLoading: false,
  isLeftNavOpen: true,
  nations: [],
  selectedNation: { value: 'All', label: 'All' },
  regions: [],
  selectedRegion: { value: 'None', label: 'None' },
  areas: [],
  selectedArea: { value: 'None', label: 'None' },
  territories: [],
  selectedTerritory: { value: 'None', label: 'None' },
  towns: [],
  selectedTown: { value: 'None', label: 'None' },
  distributionHouses: [],
  routes: [],
  selectedRoute: { value: 'None', label: 'None' },
  outlets: [],
  selectedOutlet: { value: 'None', label: 'None' },
  isOutletsListExpanded: false,
  isSrRouteOn: false,
  lateOrderOutlets: [],
  fastOrderOutlets: [],
  decliningOutlets: [],
  nonCompliantOutlets: [],
  outletsFilterOptions: [
    { value: 'all-outlets', label: 'All Outlets', icon: AllInclusive },
    { value: 'late-order-outlets', label: 'Late Order Outlets', icon: AccessTime },
    { value: 'fast-order-outlets', label: 'Fast Order Outlets', icon: Bolt },
    { value: 'declining-outlets', label: 'Declining Outlets', icon: ThumbDownOffAlt },
    { value: 'non-compliant-outlets', label: 'Non-compliant Outlets', icon: RemoveCircleOutline },
    { value: 'zero-sales-outlets', label: 'Zero Sales Outlets', icon: HighlightOff }
  ],
  selectedOutletsFilterOption: { value: 'all-outlets', label: 'All Outlets' },
  isBkoiOutletsOn: false,
  isFixedOutletsOn: false,
  isFixedRoutesOn: false,
  bkoiOutlets: [],
  fixedOutlets: [],
  fixedRoutes: [],
  isBkoiClustersOn: false,
  bkoiClusters: [],
  isTownOutletsOn: false,
  isBkoiTownOutletsOn: false,
  isRoutesLayerVisible: true,
  isAllDistributionHousesOn: false,
  allDistributionHouses: []
}

const navReducer = (state=initialState, action) => {
  switch(action.type) {
    case NavActionTypes.SET_IS_NAV_LOADING:
      return {
        ...state,
        isNavLoading: action.payload
      }
    
    case NavActionTypes.SET_IS_LEFT_NAV_OPEN:
      return {
        ...state,
        isLeftNavOpen: action.payload
      }
    
    case NavActionTypes.SET_NATIONS:
      return {
        ...state,
        nations: action.payload
      }
    
    case NavActionTypes.SET_SELECTED_NATION:
      return {
        ...state,
        selectedNation: action.payload
      }
    
    case NavActionTypes.SET_REGIONS:
      return {
        ...state,
        regions: action.payload
      }
    
    case NavActionTypes.SET_SELECTED_REGION:
      return {
        ...state,
        selectedRegion: action.payload
      }
    
    case NavActionTypes.SET_AREAS:
      return {
        ...state,
        areas: action.payload
      }
    
    case NavActionTypes.SET_SELECTED_AREA:
      return {
        ...state,
        selectedArea: action.payload
      }
    
    case NavActionTypes.SET_TERRITORIES:
      return {
        ...state,
        territories: action.payload
      }
    
    case NavActionTypes.SET_SELECTED_TERRITORY:
      return {
        ...state,
        selectedTerritory: action.payload
      }
    
    case NavActionTypes.SET_TOWNS:
      return {
        ...state,
        towns: action.payload
      }
    
    case NavActionTypes.SET_SELECTED_TOWN:
      return {
        ...state,
        selectedTown: action.payload
      }
    
    case NavActionTypes.SET_DISTRIBUTION_HOUSES:
      return {
        ...state,
        distributionHouses: action.payload
      }
    
    case NavActionTypes.SET_ROUTES:
      return {
        ...state,
        routes: action.payload
      }
    
    case NavActionTypes.SET_SELECTED_ROUTE:
      return {
        ...state,
        selectedRoute: action.payload
      }
    
    case NavActionTypes.SET_OUTLETS:
      return {
        ...state,
        outlets: action.payload
      }
    
    case NavActionTypes.SET_SELECTED_OUTLET:
      return {
        ...state,
        selectedOutlet: action.payload
      }
    
    case NavActionTypes.SET_IS_OUTLETS_LIST_EXPANDED:
      return {
        ...state,
        isOutletsListExpanded: action.payload
      }
    
    case NavActionTypes.SET_IS_SR_ROUTE_ON:
      return {
        ...state,
        isSrRouteOn: action.payload
      }
    
    case NavActionTypes.SET_LATE_ORDER_OUTLETS:
      return {
        ...state,
        lateOrderOutlets: action.payload
      }
    
    case NavActionTypes.SET_FAST_ORDER_OUTLETS:
      return {
        ...state,
        fastOrderOutlets: action.payload
      }
    
    case NavActionTypes.SET_DECLINING_OUTLETS:
      return {
        ...state,
        decliningOutlets: action.payload
      }
    
    case NavActionTypes.SET_NON_COMPLIANT_OUTLETS:
      return {
        ...state,
        nonCompliantOutlets: action.payload
      }
    
    case NavActionTypes.SET_OUTLETS_FILTER_OPTIONS:
      return {
        ...state,
        outletsFilterOptions: action.payload
      }
    
    case NavActionTypes.SET_SELECTED_OUTLETS_FILTER_OPTION:
      return {
        ...state,
        selectedOutletsFilterOption: action.payload
      }
    
    case NavActionTypes.SET_IS_BKOI_OUTLETS_ON:
      return {
        ...state,
        isBkoiOutletsOn: action.payload
      }
    
    case NavActionTypes.SET_BKOI_OUTLETS:
      return {
        ...state,
        bkoiOutlets: action.payload
      }
    
    case NavActionTypes.SET_IS_FIXED_OUTLETS_ON:
      return {
        ...state,
        isFixedOutletsOn: action.payload
      }
    
    case NavActionTypes.SET_IS_FIXED_ROUTES_ON:
      return {
        ...state,
        isFixedRoutesOn: action.payload
      }
    
    case NavActionTypes.SET_FIXED_OUTLETS:
      return {
        ...state,
        fixedOutlets: action.payload
      }
    
    case NavActionTypes.SET_FIXED_ROUTES:
      return {
        ...state,
        fixedRoutes: action.payload
      }
    
    case NavActionTypes.SET_IS_BKOI_CLUSTERS_ON:
      return {
        ...state,
        isBkoiClustersOn: action.payload
      }
    
    case NavActionTypes.SET_BKOI_CLUSTERS:
      return {
        ...state,
        bkoiClusters: action.payload
      }
    
    case NavActionTypes.SET_IS_TOWN_OUTLETS_ON:
      return {
        ...state,
        isTownOutletsOn: action.payload
      }
    
    case NavActionTypes.SET_IS_BKOI_TOWN_OUTLETS_ON:
      return {
        ...state,
        isBkoiTownOutletsOn: action.payload
      }
    
    case NavActionTypes.SET_IS_ROUTES_LAYER_VISIBLE:
      return {
        ...state,
        isRoutesLayerVisible: action.payload
      }
    
    case NavActionTypes.SET_IS_ALL_DISTRIBUTION_HOUSES_ON:
      return {
        ...state,
        isAllDistributionHousesOn: action.payload
      }
    
    case NavActionTypes.SET_ALL_DISTRIBUTION_HOUSES:
      return {
        ...state,
        allDistributionHouses: action.payload
      }

    default:
      return state
  }
}

export default navReducer
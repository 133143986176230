import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { styled } from '@mui/material/styles'
import { AppBar, Toolbar, IconButton, Typography, Tooltip, Avatar, Menu, MenuItem, ListItemIcon, Box, Stack } from '@mui/material'
import { Menu as MenuIcon, Logout } from '@mui/icons-material'

// Import Actions & Methods
import { setIsLeftNavOpen } from '../../redux/actions/navActions'
import { logout } from '../../redux/actions/authActions'

class TopNav extends React.PureComponent {
  state = {
    accMenuAnchorEl: null,
  }

  // Open Account Settings Menu
  _openAccountMenu = e => {
    this.setState({ accMenuAnchorEl: e.currentTarget })
  }

  // Close Account Settings Menu
  _closeAccountMenu = () => {
    this.setState({ accMenuAnchorEl: null })
  }

  // On Drawer Open
  _onDrawerOpen = () => {
    const { dispatch } = this.props
    dispatch( setIsLeftNavOpen(true) )
  }

  // Get Avatar String
  _stringAvatar = name => ({
    sx: {
      width: '32px',
      height: '32px'
    },
    children: name ? name.toString().split('.')[0].trim()[0].toUpperCase() : null
  })

  // On Logout
  _onLogout = () => {
    const { dispatch } = this.props
    dispatch( logout() )
  }
  
  render() {
    const { appBarProps, isLeftNavOpen, user } = this.props
    const { accMenuAnchorEl } = this.state
    const accMenuOpen = Boolean(accMenuAnchorEl)

    return (
      <StyledAppBar position='sticky' open={ isLeftNavOpen } { ...appBarProps }>
        <Toolbar disableGutters={ true } variant='dense'>
          { !isLeftNavOpen &&
            <IconButton
              color='inherit'
              aria-label='open drawer'
              size='small'
              onClick={ this._onDrawerOpen }
            >
              <MenuIcon fontSize='small' />
            </IconButton>
          }

          <Typography sx={{ mx: 'auto' }}>{ 'Retail Trace Dashboard' }</Typography>
          <Box sx={{ mr: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack 
              direction={ 'row' } 
              spacing={ 2 }
              alignItems={ 'center' }
              justifyContent={ 'center' }              
            >
              <Typography variant='body2' color='inherit' noWrap>{ user?.name ?? '' }</Typography>
              <Tooltip
                title={ user.email ? user.email : 'Account Settings' }
                arrow={ true }
              >            
                <IconButton
                  size='small'
                  aria-controls={ accMenuOpen ? 'account-settings-menu' : undefined }
                  aria-haspopup='true'
                  aria-expanded={ accMenuOpen ? 'true' : undefined }
                  onClick={ this._openAccountMenu }                
                >
                  <Avatar
                    { ...this._stringAvatar(user.name) }
                  />
                </IconButton>
              </Tooltip>
              </Stack>
          </Box>          
        </Toolbar>
        <React.Fragment>
          <Menu
            anchorEl={ accMenuAnchorEl }
            id='account-settings-menu'
            open={ accMenuOpen }
            onClose={ this._closeAccountMenu }
            onClick={ this._closeAccountMenu }
            PaperProps={ menuPaperProps }
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              dense={ true }
              onClick={ this._onLogout }
            >
              <ListItemIcon>
                <Logout fontSize='small' />
              </ListItemIcon>
              { 'Logout' }
            </MenuItem>
          </Menu>
        </React.Fragment>
      </StyledAppBar>
    )
  }
}

// Styles
const StyledAppBar = styled(AppBar, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  transition: theme.transitions.create([ 'margin', 'width' ], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${ 320 }px)`,
    marginLeft: `${ 320 }px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const menuPaperProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  }
}

// Prop Types
TopNav.propTypes = {
  appBarProps: PropTypes.object,
  isLeftNavOpen: PropTypes.bool
}

TopNav.defaultProps = {
  appBarProps: {},
  isLeftNavOpen: false
}

const mapStateToProps = state => ({
  isLeftNavOpen: state?.nav?.isLeftNavOpen ?? false,
  user: state?.auth?.user ?? {}
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(TopNav)
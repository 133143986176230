import axios from 'axios'
import Pusher from 'pusher-js'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { processRowObject } from 'kepler.gl/processors'
import { removeDataset, addDataToMap, updateMap, removeLayer, layerConfigChange, layerVisConfigChange, layerVisualChannelConfigChange, layerTextLabelChange, interactionConfigChange, onMapClick } from 'kepler.gl/actions'
import { setTextLabel } from './mapActions'
import { TraceActionTypes } from './actionTypes'
import { getTraceCompanyToken } from './authActions'
import { uniqByIndex } from '../../utils/mapUtils'
import { TRACE, API } from '../../App.config'

// Dayjs Relative Time Plugin
dayjs.extend(relativeTime)

// Set Is Trace Loading
export function setIsTraceLoading(isTraceLoading) {
  return dispatch => {
    dispatch({ type: TraceActionTypes.SET_IS_TRACE_LOADING, payload: isTraceLoading })
  }
}

// Set Is Trace On
export function setIsTraceOn(isTraceOn) {
  return dispatch => {
    dispatch({ type: TraceActionTypes.SET_IS_TRACE_ON, payload: isTraceOn })
  }
}

// Set Trace Users
export function setTraceUsers(traceUsers) {
  return dispatch => {
    dispatch({ type: TraceActionTypes.SET_TRACE_USERS, payload: traceUsers })
  }
}

// Update Trace Users
export function updateTraceUsers(traceUsers) {
  return dispatch => {
    dispatch({ type: TraceActionTypes.UPDATE_TRACE_USERS, payload: traceUsers })
  }
}

// Activate Trace
export function activateTrace() {
  return (dispatch, getState) => {
    // Get Auth Token
    const authToken = getTraceCompanyToken()

    // Set Is Trace Loading
    dispatch( setIsTraceLoading(true) )

    // Hit API to get the latest Position for all first time
    axios.get(TRACE.TRACE_API, { headers: { Authorization: `Bearer ${ authToken }` } })
      .then(res => {
        // Update Trace Data First Time From API
        const newData = res.data.users

        // Transform Data
        const isFixedRoutesOn = getState()?.nav?.isFixedRoutesOn ?? false
        transformTraceApiData(newData, isFixedRoutesOn)
          .then(transformedNewData => {
            dispatch( updateTraceDataOnMap(transformedNewData) )
          })
          .catch(err => {
            console.error(err)
          })
      })
      .then(() => {
        // Set Is Trace Data Loading
        dispatch( setIsTraceLoading(false) )

        // Activate Trace Socket Connection
        dispatch( activateTraceSocket() )

        // Check Online Status
        const checkOnlineIntervalId = setInterval(function checkOnline() {
          const traceDataset = getState()?.keplerGl?.map?.visState?.datasets[ TRACE.DATA_ID ]
          if(!traceDataset) {
            return clearInterval(checkOnlineIntervalId)
          }

          dispatch( checkTraceOnlineStatus() )
          return checkOnline
        }(), 30000)
      })
      .catch(err => {
        console.error(err)

        // Set Is Trace Data Loading
        dispatch( setIsTraceLoading(false) )
      })
  }
}

// Deactivate Trace
export function deactivateTrace() {
  return dispatch => {
    // Remove Trace Dataset
    dispatch( onMapClick() )
    dispatch( removeDataset(TRACE.DATA_ID) )
    dispatch( setTraceUsers([]) )

    if(window.pusherChannel) {
      window.pusher.unsubscribe(window.pusherChannel)
      delete window.pusher
      delete window.pusherChannel
    }

  }
}

// Activate Trace Socket
function activateTraceSocket() {
  return (dispatch, getState) => {
    // Get Auth Token
    const authToken = getTraceCompanyToken()

    // Get Channel and Event from API
    axios.get(TRACE.AUTH_API, { headers: { Authorization: `Bearer ${ authToken }` } })
      .then(res => {
        const channel = res?.data?.info?.connections?.channel.name ?? ''
        const event = res?.data?.info?.connections?.channel?.events?.find(item => item.event_type === 'GENERAL')?.name ?? ''

        window.pusher = new Pusher(TRACE.KEY, {
          wsHost: TRACE.HOST,
          wsPort: TRACE.PORT,
          wssPort: TRACE.PORT,
          wsPath: '',
          disableStats: true,
          authEndpoint: TRACE.AUTH_ENDPOINT,
          cluster: TRACE.CLUSTER,
          auth: {
            headers: {
              Authorization: `Bearer ${ authToken }`
            }
          },
          enabledTransports: [ 'ws', 'wss' ]
        })

        window.pusher.subscribe(`private-${ channel }`).bind(`${ event }`, data => {
          let newData = JSON.parse(data?.position) ?? null

          if(newData) {
            const isFixedRoutesOn = getState()?.nav?.isFixedRoutesOn ?? false
            getUsersById([ newData ], isFixedRoutesOn)
              .then(srList => {
                const newSrList = srList?.length ? srList[0] : {}

                // Transform Data
                const transformedNewData = transformTraceSocketData(newSrList)

                dispatch( updateTraceDataOnMap([ transformedNewData ]) )
                
              })
              .catch(err => {
                console.error(err)
              })
          }
        })

        // Add Channel to Global scope to unsubscribe
        window.pusherChannel = `private-${ channel }`

      })
      .catch(err => {
        console.error(err)
      })
  }
}

// Add/Update Trace Data
export function updateTraceDataOnMap(newData) {
  return (dispatch, getState) => {
    // Get Current Datasets and Config
    const dataInfo = { id: TRACE.DATA_ID, label: TRACE.DATA_LABEL }
    const options = { readOnly: true, keepExistingConfig: true }
    const { zoom, latitude, longitude } = getState()?.keplerGl?.map?.mapState ?? {}
    const traceDataset = getState()?.keplerGl?.map?.visState?.datasets[ TRACE.DATA_ID ] ?? null

    // Update Trace Users in Trace Reducer
    dispatch( updateTraceUsers(newData) )

    // Check if Trace Dataset Exists
    if(traceDataset) {
      // If Trace Dataset exists

      // Process New Data
      let processedNewData = processRowObject(newData).rows

      // Check if same data Id exists in the dataset
      const dataLength = traceDataset.allData.length
      const userIdIndex = traceDataset.fields.findIndex(el => el.name === 'user_id')
      for(let i = 0; i < dataLength; i++) {
        const indexAt = processedNewData.findIndex(item => item[userIdIndex] === traceDataset.allData[i][userIdIndex])
        if(indexAt >= 0) {
          // Fill All Data except user_id and name
          const length = traceDataset.allData[i].length
          for(let j = 2; j < length; j++) {
            traceDataset.allData[i][j] = processedNewData[indexAt][j]
          }

          processedNewData = processedNewData.filter(item => item[userIdIndex] !== traceDataset.allData[i][userIdIndex])
        }
      }

      const data = {
        fields: traceDataset.fields.map(field => ({
          analyzerType: field.analyzerType,
          format: field.format,
          name: field.name,
          tableFieldIndex: field.tableFieldIndex,
          type: field.type
        })),
        rows: [ ...traceDataset.allData, ...processedNewData ]
      }

      // Sort by active_position field index for colors
      const activePositionFieldIndex = data?.fields?.findIndex(d => d?.name === 'active_position') ?? -1
      if(activePositionFieldIndex >= 0) {
        data.rows = data.rows.sort((a, b) => {
          const activePositionA = a[ activePositionFieldIndex ]?.toLowerCase()
          const activePositionB = b[ activePositionFieldIndex ]?.toLowerCase()
  
          if(activePositionA < activePositionB) {
            return -1
          }
  
          if(activePositionA > activePositionB) {
            return 1
          }
  
          return 0
        })
      }
      const dataset = { info: dataInfo, data }

      // Dispatch `addDataToMap`
      dispatch( addDataToMap({ datasets: dataset, options }) )

      // Update Map State to Current State
      dispatch( updateMap({ zoom, latitude, longitude }) )

      // Get Uniq SR Postions
      const layers = getState()?.keplerGl?.map?.visState?.layers ?? []
      layers.forEach(l => {
        if(l.type === 'icon' && l.config.dataId === dataInfo.id) {
          // Set Color Field by to distinguish SR Position
          const _traceDataset = getState().keplerGl.map.visState.datasets[ l.config.dataId ]
          const activePositionField = _traceDataset?.fields?.find(el => el.name === 'active_position') ?? null

          // Get Uniq SR Postions
          const uniqSrPositions = uniqByIndex(_traceDataset?.allData ?? [], activePositionField.tableFieldIndex-1)
          let colors = TRACE.COLOR_PALETTE.filter(c =>
            uniqSrPositions.find(u => u[ activePositionField.tableFieldIndex-1 ] === c.value)
          )
          .map(tc => tc.color)

          // Color Range
          const colorRange = {
            category: 'Barikoi',
            colors,
            name: 'Trace SO Inside/Outside',
            type: 'quantile'
          }

          // Dispatch Color Range
          dispatch( layerVisConfigChange(l, { colorRange }) )

          // Dispatch Color Field By
          dispatch( layerVisualChannelConfigChange(l, { colorField: activePositionField }, 'color') )
        }
      })

    } else {
      // If Trace Dataset doesn't exist

      // Remove Dataset
      dispatch( onMapClick() )
      dispatch( removeDataset(TRACE.DATA_ID) )
      
      // Build Dataset & Sort By active_position for colors
      const data = processRowObject(newData)
      const activePositionFieldIndex = data?.fields?.findIndex(d => d?.name === 'active_position') ?? -1
      if(activePositionFieldIndex >= 0) {
        data.rows = data.rows.sort((a, b) => {
          const activePositionA = a[ activePositionFieldIndex ]?.toLowerCase()
          const activePositionB = b[ activePositionFieldIndex ]?.toLowerCase()
  
          if(activePositionA < activePositionB) {
            return -1
          }
  
          if(activePositionA > activePositionB) {
            return 1
          }
  
          return 0
        })
      }
      const dataset = { info: dataInfo, data }

      // Add Data To Map
      dispatch( addDataToMap({ datasets: dataset, options: { ...options, centerMap: true } }) )

      // Update Map State to Current State
      dispatch( updateMap({ zoom, latitude, longitude }) )

      // Apply Layer Configs
      const layers = getState()?.keplerGl?.map?.visState?.layers ?? []
      layers.forEach((l, i) => {
        if(l.config.dataId === dataInfo.id) {
          if(l.type === 'point') {
            // Remove Unnecessary Point Layer
            dispatch( removeLayer(i) )

          } else if(l.type === 'icon') {
            // Set Layer Label
            dispatch( layerConfigChange(l, { label: 'Trace' }) )

            // Set Trace Text Label to `name`
            const _traceDataset = getState().keplerGl.map.visState.datasets[ l.config.dataId ]
            const nameField = _traceDataset.fields.find(el => el.name === 'name')
            if(nameField) {
              dispatch( setTextLabel(l, nameField) )
            }

            // Set Text Label Color
            l.config.textLabel.forEach((label, li) => {
              dispatch( layerTextLabelChange(l, li, 'color', [ 0, 0, 0 ]) )
            })

            // Set Color Field by to distinguish SR Position
            const activePositionField = _traceDataset?.fields?.find(el => el.name === 'active_position') ?? null

            // Get Uniq SR Postions
            const uniqSrPositions = uniqByIndex(_traceDataset?.allData ?? [], activePositionField.tableFieldIndex-1)
            let colors = TRACE.COLOR_PALETTE.filter(c =>
              uniqSrPositions.find(u => u[ activePositionField.tableFieldIndex-1 ] === c.value)
            )
            .map(tc => tc.color)

            // Color Range
            const colorRange = {
              category: 'Barikoi',
              colors,
              name: 'Trace SO Inside/Outside',
              type: 'quantile'
            }

            // Dispatch Color Range
            dispatch( layerVisConfigChange(l, { colorRange }) )

            // Dispatch Color Field By
            dispatch( layerVisualChannelConfigChange(l, { colorField: activePositionField }, 'color') )

            // Tooltip Configs
            const fieldsToShow = TRACE.TOOLTIP_FIELDS
            const { tooltip } = getState().keplerGl.map.visState.interactionConfig
            tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
            dispatch( interactionConfigChange(tooltip) )
          }
        }
      })
    }
  }
}

// Check Trace Online Status
function checkTraceOnlineStatus() {
  return (dispatch, getState) => {
    // Get Current Datasets and Config
    // Get Existing Data and update active_status
    const dataInfo = { id: TRACE.DATA_ID, label: TRACE.DATA_LABEL }
    const options = { readOnly: true, keepExistingConfig: true }
    const { zoom, latitude, longitude } = getState().keplerGl.map.mapState
    const traceDataset = getState().keplerGl.map.visState.datasets[ TRACE.DATA_ID ]

    // Get active_status Index
    const activeIndex = traceDataset.fields.findIndex(el => el.name === 'active_status')
    const positionIndex = traceDataset.fields.findIndex(el => el.name === 'position')
    const activePositionIndex = traceDataset.fields.findIndex(el => el.name === 'active_position')
    const updatedAtIndex = traceDataset.fields.findIndex(el => el.name === 'updated_at')
    const lastOnlineIndex = traceDataset.fields.findIndex(el => el.name === 'last_online')

    // Check if same data Id exists in the dataset as active users
    const dataLength = traceDataset.allData.length
    for(let i = 0; i < dataLength; i++) {
      // Check `updated_at`. If `online` and 2 minutes passed after `updated_at` then set `offline`
      const updatedAt = traceDataset.allData[i][updatedAtIndex]
      const updatedAtDate = new Date(updatedAt)
      const updatedAtTime = updatedAtDate.getTime()
      const currentDate = new Date()
      const currentDateTime = currentDate.getTime()
      const elapsed = currentDateTime - updatedAtTime
      const activeStatus = traceDataset.allData[i][activeIndex]
      const offlineThresholdDelay = 600000

      if(activeStatus === 'Online' && elapsed >= offlineThresholdDelay) {
        traceDataset.allData[i][activeIndex] = 'Offline'
        traceDataset.allData[i][activePositionIndex] = `${ traceDataset.allData[i][positionIndex] }/Offline`
      }

      // Update `last_online`
      traceDataset.allData[i][lastOnlineIndex] = dayjs(updatedAt).fromNow()
    }

    const data = {
      fields: traceDataset.fields,
      rows: traceDataset.allData.filter(td => td[activeIndex] === 'Online')
    }
    const dataset = { info: dataInfo, data }

    // Dispatch `addDataToMap`
    dispatch( addDataToMap({ datasets: dataset, options }) )

    // Update Map State to Current State
    dispatch( updateMap({ zoom, latitude, longitude }) )
  }
}

///////////////
// Utilities //
///////////////

// Transform Trace API Data
export function transformTraceApiData(data, isFixedRoutesOn=false, filterOnline=true) {
  return getUsersById(data, isFixedRoutesOn)
    .then(srList => {
      let apiData = srList.map(d => ({
        user_id: d.user_id,
        name: d.name,
        longitude: d.longitude,
        latitude: d.latitude,
        updated_at: d.updated_at,
        active_status: d.active_status === 1 ? 'Online' : 'Offline',
        last_online: dayjs(d.updated_at).fromNow(),
        designation: d.designation,
        route_id: d.route_id,
        route_name: d.route_name,
        position: d.position ?? 'Outside Route',
        active_position: `${ d.position ?? 'Outside Route' }/${ d.active_status === 1 ? 'Online' : 'Offline' }`,
        icon: 'profile'
      }))

      if(filterOnline) {
        apiData = apiData.filter(sr => sr.active_status === 'Online')
      }

      return apiData
    })
    .catch(err => {
      throw err
    })
}

// Transform Trace Socket Data
function transformTraceSocketData(data) {
  const socketData = {
    user_id: data.user_id,
    name: data.name,
    longitude: data.longitude,
    latitude: data.latitude,
    updated_at: data.updated_at,
    active_status: data.active_status === 1 ? 'Online' : 'Offline',
    last_online: dayjs(data.updated_at).fromNow(),
    designation: data.designation,
    route_id: data.route_id,
    route_name: data.route_name,
    position: data.position ?? 'Outside Route',
    active_position: `${ data.position ?? 'Outside Route' }/${ data.active_status === 1 ? 'Online' : 'Offline' }`,
    icon: 'profile'
  }

  return socketData
}

// Get Users By ID
function getUsersById(users, fixed_route) {
  // Get SR List Data from API
  const authUser = JSON.parse(localStorage.getItem('user') ?? null)
  const sr_list = users.filter(u => u.user_id && u.longitude && u.latitude)
  return axios.post(API.GET_SR_BY_ID, { sr_list, fixed_route: fixed_route ? 1 : 0, auth_user: authUser })
    .then(res => {
      const { sr_list } = res.data

      // If SR List Invalid
      if(!sr_list?.length) {
        return []
      }

      return sr_list
    })
    .catch(err => {
      console.error(err)
      throw err
    })
}
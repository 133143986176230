import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { List, ListItemButton, Box, Typography } from '@mui/material'

// Import Actions & Methods
import { flyToLocation } from '../../redux/actions/mapActions'

class OutletList extends React.PureComponent {
  // On Outlet Click
  _onOutletClick = outlet => {
    const { dispatch } = this.props
    
    // Fly To Outlet Location
    dispatch( flyToLocation({ longitude: outlet.longitude, latitude: outlet.latitude, zoom: 22 }) )
  }

  render() {
    const { outlets } = this.props

    return (
      <List
        dense={ true }
        disablePadding={ true }
        style={{
          width: '100%',
          color: '#a0a0a0',
          overflow: 'auto',
          maxHeight: '360px'
        }}
      >
        {
          outlets.map((a, i) =>
            <ListItemButton
              key={ i }
              dense={ true }
              disableGutters={ true }
              onClick={ () => this._onOutletClick(a) }
              sx={{ p: 0 }}
            >
              <Box
                sx={{
                  padding: '8px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start'
                }}
              >
                <Box width='100%'>
                  <Typography style={{ fontSize: '0.6rem', fontWeight: 500 }}>{ a?.business_name ?? 'N/A' }</Typography>
                  <Typography style={{ fontSize: '0.55rem', color: '#a0a0a0' }}>{ 'Address: ' + a?.address ?? 'N/A' }</Typography>
                  <Typography style={{ fontSize: '0.55rem', color: '#a0a0a0' }}>{ 'Route: ' + a?.route_name ?? 'N/A' }</Typography>
                </Box>

                <Box width='100%' display='flex' flexDirection='row' justifyContent='flex-end' alignItems='flex-start'>
                  <Box display='flex' flexDirection='column' justifyContent='flex-start' alignItems='flex-start'>
                    <Typography style={{ fontSize: '0.55rem', color: '#a0a0a0' }}>{ 'Code: ' + a?.outlet_code ?? 'N/A' }</Typography>
                    { a?.visit_order &&
                      <Typography style={{ fontSize: '0.55rem', color: '#a0a0a0' }}>{ 'Order Seq: ' + a?.visit_order ?? 'N/A' }</Typography>
                    }
                  </Box>
                </Box>
              </Box>
            </ListItemButton>
          )
        }
      </List>
    )
  }
}

// Prop Types
OutletList.propTypes = {
  outlets: PropTypes.array,
  dispatch: PropTypes.func
}

OutletList.defaultProps = {
  outlets: [],
  dispatch: () => null
}

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(null, mapDispatchToProps)(OutletList)
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

// Import Components
import { LocalizationProvider } from '@mui/lab'
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import App from './App'

// Import Styles
import './index.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

// Import Store
import store from './redux/store'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <LocalizationProvider dateAdapter={ AdapterDayjs }>
        <App />
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box } from '@mui/material'
import StyledSwitch from '../common/StyledSwitch'

// Import Actions & Methods
import { setIsFixedRoutesOn } from '../../redux/actions/navActions'

const FixedRoutesControl = ({ dispatch, isFixedRoutesOn }) => {
  // On Fixed Routes Control Change
  const _onFixedRoutesControlChange = (e, value) => {
    dispatch( setIsFixedRoutesOn(value) )
  }

  return (
    <Box>
      <StyledSwitch
        label='Fixed Routes'
        checked={ isFixedRoutesOn }
        value={ isFixedRoutesOn }
        onChange={ _onFixedRoutesControlChange }
      />
    </Box>
  )
}

// Prop Types
FixedRoutesControl.propTypes = {
  isFixedRoutesOn: PropTypes.bool,
  dispatch: PropTypes.func
}

FixedRoutesControl.defaultProps = {
  isFixedRoutesOn: false,
  dispatch: () => null
}

const mapStateToProps = state => ({
  isFixedRoutesOn: state?.nav?.isFixedRoutesOn ?? false
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(FixedRoutesControl)
import { combineReducers } from 'redux'
import { keplerGlReducer, uiStateUpdaters } from 'kepler.gl/reducers'
import navReducer from './navReducer'
import statReducer from './statReducer'
import mapReducer from './mapReducer'
import traceReducer from './traceReducer'
import authReducer from './authReducer'
import featureControlReducer from './featureControl'
import socketReducer from './socketReducer'
import configsReducer from './configsReducer'

// Kepler GL Custom Reducer
const customKeplerGlReducer = keplerGlReducer.initialState({
  uiState: {
    mapControls: {
      ...uiStateUpdaters.DEFAULT_MAP_CONTROLS,
      splitMap: {
        show: false,
        active: false
      },
      mapDraw: {
        show: false,
        active: false
      },
      mapLocale: {
        show: false,
        active: false
      }
    }
  }
})

const rootReducer = combineReducers({
  keplerGl: customKeplerGlReducer,
  nav: navReducer,
  stat: statReducer,
  map: mapReducer,
  trace: traceReducer,
  auth: authReducer,
  featureControl: featureControlReducer,
  socket: socketReducer,
  configs: configsReducer
})

export default rootReducer
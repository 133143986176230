import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

// Import Components
import { Box, CssBaseline, CircularProgress, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import ProtectedRoute from './components/common/ProtectedRoute'
import UnileverDashboard from './components/pages/UnileverDashboard'
import Login from './components/pages/Login'

// Import Styles
import './App.css'

// Import Actions
import { validateUser } from './redux/actions/authActions'

class App extends React.PureComponent {
  componentDidMount() {
    const { dispatch } = this.props

    // Validate User
    const token = localStorage.getItem('token')
    dispatch( validateUser(token) )
  }

  _onNotistackClickDismiss = key => () => {
    this.notistackRef.closeSnackbar(key)
  }

  render() {
    const { isAuthenticated, isValidating } = this.props

    return (
      <SnackbarProvider
        ref={ node => this.notistackRef = node }
        maxSnack={ 2 }
        action={ key => (
          <IconButton size='small' edge='end' onClick={ this._onNotistackClickDismiss(key) }>
            <Close fontSize='small' />
          </IconButton>
        )}
      >
        <Box sx={ containerStyles }>
          { isValidating ?
            <Box style={ loaderStyles }>
              <CircularProgress />
            </Box>
            :
            <BrowserRouter>
              <Routes>
                <Route
                  exact={ true }
                  path='/login'
                  element={
                    isAuthenticated ?
                      <Navigate to='/' />
                      :
                      <Login />
                  }
                />

                <Route
                  exact={ true }
                  path='/'
                  element={
                    <ProtectedRoute
                      isAuthenticated={ isAuthenticated }
                      element={
                        <>
                          <CssBaseline />
                          <UnileverDashboard />
                        </>
                      }
                    />
                  }
                />
              </Routes>
            </BrowserRouter>
          }
        </Box>
      </SnackbarProvider>
    )
  }
}

// Styles
const containerStyles = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center'
}

const loaderStyles = {
  height: '100vh', 
  width: '100vw', 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center'
}

// Prop Types
App.propTypes = {
  isAuthenticated: PropTypes.bool,
  isValidating: PropTypes.bool,
  dispatch: PropTypes.func
}

App.defaultProps = {
  isAuthenticated: false,
  isValidating: false,
  dispatch: () => null
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isValidating: state.auth.isValidating
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(App)
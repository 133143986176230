import axios from 'axios'
import dayjs from 'dayjs'
import { NavActionTypes } from './actionTypes'
import { loadNationsToMap, clearNationsFromMap, loadRegionsToMap, clearRegionsFromMap, loadAreasToMap, clearAreasFromMap, loadTerritoriesToMap, clearTerritoriesFromMap, loadTownsToMap, clearTownsFromMap, loadRoutesToMap, clearRoutesFromMap, loadSrRouteToMap, clearSrRouteFromMap, loadOutletsToMap, clearOutletsFromMap, clearBkoiOutletsFromMap, loadBkoiOutletsToMap, clearFixedOutletsFromMap, loadFixedOutletsToMap, loadFixedRoutesToMap, clearFixedRoutesFromMap, clearBkoiClustersFromMap, loadBkoiClustersToMap, loadAllDistributionHousesToMap, clearAllDistributionHousesFromMap } from './mapActions'
import { decodeVhRouteShape, datasetToRowObject } from '../../utils/mapUtils'
import { formatDuration, sortOptionsAsc } from '../../utils/utils'
import { SR, OUTLETS, API } from '../../App.config'

/////////
// NAV //
/////////

// Set Is Nav Loading
export function setIsNavLoading(isNavLoading) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_IS_NAV_LOADING, payload: isNavLoading })
  }
}

// Set Is Left Nav Open Action
export function setIsLeftNavOpen(isLeftNavOpen) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_IS_LEFT_NAV_OPEN, payload: isLeftNavOpen })
  }
}

/////////////
// NATIONS //
/////////////

// Set Nations
export function setNations(nations) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_NATIONS, payload: nations })
  }
}

// Set Selected Nation
export function setSelectedNation(selectedNation) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_SELECTED_NATION, payload: selectedNation })
  }
}

// Get Nations Data
export function getNations(startDate='', endDate='', brand='', sku='', nation_id='') {
  return dispatch => {
    // Set Is Nav Loading
    dispatch( setIsNavLoading(true) )

    // Clear Nations From Map
    dispatch( clearNationsFromMap() )

    // Get Nations Data from API
    axios.get(API.GET_NATIONS, { params: { start_date: startDate, end_date: endDate, brand, sku, nation_id } })
      .then(res => {
        const { nations } = res.data

        // If Nations Invalid
        if(!nations?.length) {
          dispatch( setNations([]) )

          // Set Is Nav Loading
          dispatch( setIsNavLoading(false) )

          return
        }

        const nationOptions = sortOptionsAsc(nations.map(n => ({ ...n, value: n.id, label: n.name })))

        dispatch( setNations(nationOptions) )

        // Load Nations To Map
        const nationGeojson = transformRowObjectListToGeojson(nations)
        dispatch( loadNationsToMap(nationGeojson) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setNations([]) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )
      })
  }
}

/////////////
// REGIONS //
/////////////

// Set Regions
export function setRegions(regions) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_REGIONS, payload: regions })
  }
}

// Set Selected Region
export function setSelectedRegion(selectedRegion) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_SELECTED_REGION, payload: selectedRegion })
  }
}

// Get Regions Data
export function getRegions(selectedNation, startDate='', endDate='', brand='', sku='', region_id='') {
  return dispatch => {
    // Set Is Nav Loading
    dispatch( setIsNavLoading(true) )

    // Clear Regions From Map
    dispatch( clearRegionsFromMap() )

    // Get Regions Data from API
    axios.get(API.GET_REGIONS, { params: { nation_id: selectedNation?.id ?? '', start_date: startDate, end_date: endDate, brand, sku, region_id } })
      .then(res => {
        const { regions } = res.data

        // If Regions Invalid
        if(!regions?.length) {
          dispatch( setRegions([]) )

          // Set Is Nav Loading
          dispatch( setIsNavLoading(false) )

          return
        }

        const regionOptions = sortOptionsAsc(regions.map(r => ({ ...r, value: r.id, label: r.name })))

        dispatch( setRegions(regionOptions) )

        // Load Regions To Map
        const regionGeojson = transformRowObjectListToGeojson(regions)
        dispatch( loadRegionsToMap(regionGeojson) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setRegions([]) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )
      })
  }
}

///////////
// AREAS //
///////////

// Set Areas
export function setAreas(areas) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_AREAS, payload: areas })
  }
}

// Set Selected Area
export function setSelectedArea(selectedArea) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_SELECTED_AREA, payload: selectedArea })
  }
}

// Get Areas Data
export function getAreas(selectedRegion, startDate='', endDate='', brand='', sku='', area_id='') {
  return dispatch => {
    // Set Is Nav Loading
    dispatch( setIsNavLoading(true) )

    // Clear Areas From Map
    dispatch( clearAreasFromMap() )

    // Get Areas Data from API
    axios.get(API.GET_AREAS, { params: { region_id: selectedRegion?.id ?? '', start_date: startDate, end_date: endDate, brand, sku, area_id } })
      .then(res => {
        const { areas } = res.data

        // If Areas Invalid
        if(!areas?.length) {
          dispatch( setAreas([]) )

          // Set Is Nav Loading
          dispatch( setIsNavLoading(false) )

          return
        }

        const areaOptions = sortOptionsAsc(areas.map(a => ({ ...a, value: a.id, label: a.name })))

        dispatch( setAreas(areaOptions) )

        // Load Areas To Map
        const areaGeojson = transformRowObjectListToGeojson(areas)
        dispatch( loadAreasToMap(areaGeojson) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setAreas([]) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )
      })
  }
}

/////////////////
// TERRITORIES //
/////////////////

// Set Territories
export function setTerritories(territories) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_TERRITORIES, payload: territories })
  }
}

// Set Selected Territory
export function setSelectedTerritory(selectedTerritory) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_SELECTED_TERRITORY, payload: selectedTerritory })
  }
}

// Get Territories Data
export function getTerritories(selectedArea, startDate='', endDate='', brand='', sku='', territory_id='') {
  return dispatch => {
    // Set Is Nav Loading
    dispatch( setIsNavLoading(true) )

    // Clear Territories From Map
    dispatch( clearTerritoriesFromMap() )

    // Get Territories Data from API
    axios.get(API.GET_TERRITORIES, { params: { area_id: selectedArea?.id ?? '', start_date: startDate, end_date: endDate, brand, sku, territory_id } })
      .then(res => {
        const { territories } = res.data

        // If Territories Invalid
        if(!territories?.length) {
          dispatch( setTerritories([]) )

          // Set Is Nav Loading
          dispatch( setIsNavLoading(false) )

          return
        }

        const territoryOptions = sortOptionsAsc(territories.map(t => ({ ...t, value: t.id, label: t.name })))

        dispatch( setTerritories(territoryOptions) )

        // Load Territories & Distribution Houses To Map
        const territoryGeojson = transformRowObjectListToGeojson(territories)
        dispatch( loadTerritoriesToMap(territoryGeojson) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setTerritories([]) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )
      })
  }
}

// Set Distribution Houses
export function setDistributionHouses(distributionHouses) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_DISTRIBUTION_HOUSES, payload: distributionHouses })
  }
}

///////////
// TOWNS //
///////////

// Set Towns
export function setTowns(towns) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_TOWNS, payload: towns })
  }
}

// Set Selected Town
export function setSelectedTown(selectedTown) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_SELECTED_TOWN, payload: selectedTown })
  }
}

// Get Towns Data
export function getTowns(selectedTerritory, startDate='', endDate='', brand='', sku='') {
  return dispatch => {
    // Set Is Nav Loading
    dispatch( setIsNavLoading(true) )

    // Clear Towns From Map
    dispatch( clearTownsFromMap() )

    // Get Towns Data from API
    axios.get(API.GET_TOWNS, { params: { territory_id: selectedTerritory?.id ?? '', start_date: startDate, end_date: endDate, brand, sku } })
      .then(res => {
        const { towns, distribution_houses } = res.data

        // If Towns Invalid
        if(!towns?.length) {
          dispatch( setTowns([]) )
          dispatch( setDistributionHouses([]) )

          // Set Is Nav Loading
          dispatch( setIsNavLoading(false) )

          return
        }

        const townOptions = sortOptionsAsc(towns.map(t => ({ ...t, value: t.id, label: t.name })))

        dispatch( setTowns(townOptions) )
        dispatch( setDistributionHouses(distribution_houses) )

        // Load Towns & Distribution Houses To Map
        const townGeojson = transformRowObjectListToGeojson(towns)
        const distributionHouses = transformDistributionHousesToIconData(distribution_houses)
        dispatch( loadTownsToMap(townGeojson, distributionHouses) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setTowns([]) )
        dispatch( setDistributionHouses([]) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )
      })
  }
}

////////////
// ROUTES //
////////////

// Set Routes
export function setRoutes(routes) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_ROUTES, payload: routes })
  }
}

// Set Selected Route
export function setSelectedRoute(selectedRoute) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_SELECTED_ROUTE, payload: selectedRoute })
  }
}

// Get Routes Data
export function getRoutes(selectedTown, startDate='', endDate='', brand='', sku='', fixed_route=false) {
  return dispatch => {
    // Set Is Nav Loading
    dispatch( setIsNavLoading(true) )

    // Clear Routes From Map
    dispatch( clearRoutesFromMap() )

    // Get Routes Data from API
    axios.get(API.GET_ROUTES, { params: { town_id: selectedTown?.id ?? '', start_date: startDate, end_date: endDate, brand, sku, fixed_route: fixed_route ? 1 : 0 } })
      .then(res => {
        const { routes } = res.data

        // If Routes Invalid
        if(!routes?.length) {
          dispatch( setRoutes([]) )

          // Set Is Nav Loading
          dispatch( setIsNavLoading(false) )

          return
        }

        const routeOptions = sortOptionsAsc(routes.map(r => ({ ...r, value: r.id, label: r.name })))

        dispatch( setRoutes(routeOptions) )

        // Load Routes To Map
        const routeGeojson = transformRowObjectListToGeojson(routes)
        dispatch( loadRoutesToMap(routeGeojson) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setRoutes([]) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )
      })
  }
}

// Set Is SR Route On
export function setIsSrRouteOn(isSrRouteOn) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_IS_SR_ROUTE_ON, payload: isSrRouteOn })
  }
}

// Get Optimized SR Route
export function getOptimizedSrRoute() {
  return (dispatch, getState) => {
    // Set Is Nav Loading
    dispatch( setIsNavLoading(true) )

    // Clear SR Route Data From Map
    dispatch( clearSrRouteFromMap() )

    // Get Outlets Dataset
    const outletDataset = getState()?.keplerGl?.map?.visState?.datasets[ OUTLETS.DATA_ID ]

    if(!outletDataset) {
      // Clear Previous SR Route Datasets
      dispatch( clearSrRouteFromMap() )
      
      // Set Is Nav Loading
      dispatch( setIsNavLoading(false) )
      return
    }

    // Get Distribution House
    const selectedTown = getState()?.nav?.selectedTown ?? {}
    const distributionHouses = getState()?.nav?.distributionHouses ?? []
    const distributionHouse = distributionHouses?.find(dh => dh.town_id === selectedTown?.id) ?? {}

    const outlets = datasetToRowObject(outletDataset)

    if(!outlets?.length) {
      // Clear Previous SR Route Datasets
      dispatch( clearSrRouteFromMap() )

      // Set Is Nav Loading
      dispatch( setIsNavLoading(false) )
      return
    }
    
    const apiParams = {
      json: {
        locations: [
          {
            lon: distributionHouse.longitude,
            lat: distributionHouse.latitude
          },
          ...outlets.map(d => ({
            lon: d.longitude,
            lat: d.latitude
          })),
          {
            lon: distributionHouse.longitude,
            lat: distributionHouse.latitude
          }
        ],
        costing: 'auto',
        directions_options: {
          units: 'kilometers'
        }
      }
    }

    fetchOptimizedSrRoute(apiParams, outlets)
      .then(({ routeGeoJson, waypoints }) => {
        // Dispatch Route Data to Map
        dispatch( loadSrRouteToMap(routeGeoJson, waypoints) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )

      })
      .catch(err => {
        console.error(err)

        // Clear Previous SR Route Datasets
        dispatch( clearSrRouteFromMap() )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )
      })
  }
}

////////////
// OUTLETS //
////////////

// Set Outlets
export function setOutlets(outlets) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_OUTLETS, payload: outlets })
  }
}

// Set Selected Outlet
export function setSelectedOutlet(selectedOutlet) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_SELECTED_OUTLET, payload: selectedOutlet })
  }
}

// Get Outlets Data
export function getOutlets(options={ town_id: '', route_id: '', start_date: '', end_date: '', brand: '', sku: '', late_hour: '17:00:00', fast_order_duration: 120 }) {
  return (dispatch, getState) => {
    const { town_id, route_id, start_date, end_date, brand, sku, late_hour, fast_order_duration } = options
    
    // Set Is Nav Loading
    dispatch( setIsNavLoading(true) )

    // Clear Outlets From Map
    dispatch( clearOutletsFromMap() )

    // Get Outlets Data from API
    axios.get(API.GET_OUTLETS, { params: { town_id, route_id, start_date, end_date, brand, sku, late_hour, fast_order_duration } })
      .then(res => {
        const { outlets, late_order_outlets, fast_order_outlets, declining_outlets, non_compliant_outlets } = res.data

        // If Outlets Invalid
        if(!outlets?.length) {
          dispatch( setOutlets([]) )
          dispatch( setLateOrderOutlets([]) )
          dispatch( setFastOrderOutlets([]) )
          dispatch( setDecliningOutlets([]) )
          dispatch( setNonCompliantOutlets([]) )

          // Set Is Nav Loading
          dispatch( setIsNavLoading(false) )

          return
        }

        const outletOptions = sortOptionsAsc(outlets.map(a => ({ ...a, value: a.id, label: a.name ?? a.business_name ?? '' })))

        dispatch( setOutlets(outletOptions) )

        if(route_id || town_id) {
          dispatch( setLateOrderOutlets(late_order_outlets) )
          dispatch( setFastOrderOutlets(fast_order_outlets) )
          dispatch( setDecliningOutlets(declining_outlets) )
          dispatch( setNonCompliantOutlets(non_compliant_outlets) )
        }

        // Load Outlets To Map
        dispatch( loadOutletsToMap(outlets, route_id ? true : false) )

        if(route_id) {
          // Set Is Nav Loading
          const srRouteDataset = getState()?.keplerGl?.map?.visState?.datasets[ SR.SR_ROUTE_DATA_ID ] ?? null
          if(srRouteDataset) {
            dispatch( setIsNavLoading(false) )
          }
        }

        // Reset Outlets Filter Option
        dispatch( setSelectedOutletsFilterOption({ value: 'all-outlets', label: 'All Outlets' }) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )
        
      })
      .catch(err => {
        console.error(err)

        dispatch( setOutlets([]) )
        dispatch( setLateOrderOutlets([]) )
        dispatch( setFastOrderOutlets([]) )
        dispatch( setDecliningOutlets([]) )
        dispatch( setNonCompliantOutlets([]) )

        // Reset Outlets Filter Option
        dispatch( setSelectedOutletsFilterOption({ value: 'all-outlets', label: 'All Outlets' }) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )
      })
  }
}

// Set Is Outlets List Expanded
export function setIsOutletsListExpanded(isOutletsListExpanded) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_IS_OUTLETS_LIST_EXPANDED, payload: isOutletsListExpanded })
  }
}

// Set Late Order Outlets
export function setLateOrderOutlets(lateOrderOutlets) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_LATE_ORDER_OUTLETS, payload: lateOrderOutlets })
  }
}

// Set Fast Order Outlets
export function setFastOrderOutlets(fastOrderOutlets) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_FAST_ORDER_OUTLETS, payload: fastOrderOutlets })
  }
}

// Set Decling Outlets
export function setDecliningOutlets(decliningOutlets) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_DECLINING_OUTLETS, payload: decliningOutlets })
  }
}

// Set Non-compliant Outlets
export function setNonCompliantOutlets(nonCompliantOutlets) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_NON_COMPLIANT_OUTLETS, payload: nonCompliantOutlets })
  }
}

// Set Outlets Filter Options
export function setOutletsFilterOptions(outletsFilterOptions) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_OUTLETS_FILTER_OPTIONS, payload: outletsFilterOptions })
  }
}

// Set Selected Outlets Filter Option
export function setSelectedOutletsFilterOption(selectedOutletsFilterOption) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_SELECTED_OUTLETS_FILTER_OPTION, payload: selectedOutletsFilterOption })
  }
}

// Set Is Bkoi Outlets On
export function setIsBkoiOutletsOn(isBkoiOutletsOn) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_IS_BKOI_OUTLETS_ON, payload: isBkoiOutletsOn })
  }
}

// Set Is Fixed Outlets On
export function setIsFixedOutletsOn(isFixedOutletsOn) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_IS_FIXED_OUTLETS_ON, payload: isFixedOutletsOn })
  }
}

// Set Is Fixed Routes On
export function setIsFixedRoutesOn(isFixedRoutesOn) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_IS_FIXED_ROUTES_ON, payload: isFixedRoutesOn })
  }
}

// Set Bkoi Outlets
export function setBkoiOutlets(bkoiOutlets) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_BKOI_OUTLETS, payload: bkoiOutlets })
  }
}

// Set Fixed Outlets
export function setFixedOutlets(fixedOutlets) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_FIXED_OUTLETS, payload: fixedOutlets })
  }
}

// Set Fixed Routes
export function setFixedRoutes(fixedRoutes) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_FIXED_ROUTES, payload: fixedRoutes })
  }
}

// Get Bkoi Outlets Data
export function getBkoiOutlets(options={ town_id: '', route_id: '' }) {
  return dispatch => {
    const { town_id, route_id } = options

    // Set Is Nav Loading
    dispatch( setIsNavLoading(true) )

    // Clear Bkoi Outlets From Map
    dispatch( clearBkoiOutletsFromMap() )

    // Get Bkoi Outlets Data from API
    axios.get(API.GET_BKOI_OUTLETS, { params: { town_id, route_id } })
      .then(res => {
        const { bkoi_outlets } = res.data

        // If Bkoi Outlets Invalid
        if(!bkoi_outlets?.length) {
          dispatch( setBkoiOutlets([]) )

          // Set Is Nav Loading
          dispatch( setIsNavLoading(false) )

          return
        }

        const bkoiOutletOptions = sortOptionsAsc(bkoi_outlets.map(a => ({ ...a, value: a.id, label: a.name ?? a.business_name ?? '' })))

        dispatch( setBkoiOutlets(bkoiOutletOptions) )

        // Load Bkoi Outlets To Map
        dispatch( loadBkoiOutletsToMap(bkoi_outlets, route_id ? true : false) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setBkoiOutlets([]) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )
      })
  }
}

// Get Fixed Outlets Data
export function getFixedOutlets(options={ town_id: '', route_id: '' }) {
  return dispatch => {
    const { town_id, route_id } = options

    // Set Is Nav Loading
    dispatch( setIsNavLoading(true) )

    // Clear Fixed Outlets From Map
    dispatch( clearFixedOutletsFromMap() )

    // Get Fixed Outlets Data from API
    axios.get(API.GET_FIXED_OUTLETS, { params: { town_id, route_id } })
      .then(res => {
        const { fixed_outlets } = res.data

        // If Fixed Outlets Invalid
        if(!fixed_outlets?.length) {
          dispatch( setFixedOutlets([]) )

          // Set Is Nav Loading
          dispatch( setIsNavLoading(false) )

          return
        }

        const fixedOutletOptions = sortOptionsAsc(fixed_outlets.map(a => ({ ...a, value: a.id, label: a.name ?? a.business_name ?? '' })))

        dispatch( setFixedOutlets(fixedOutletOptions) )

        // Load Fixed Outlets To Map
        dispatch( loadFixedOutletsToMap(fixed_outlets, route_id ? true : false) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setFixedOutlets([]) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )
      })
  }
}

// Get Fixed Routes Data
export function getFixedRoutes(options={ town_id: '' }) {
  return dispatch => {
    const { town_id } = options

    // Set Is Nav Loading
    dispatch( setIsNavLoading(true) )

    // Clear Fixed Routes From Map
    dispatch( clearFixedRoutesFromMap() )

    // Get Fixed Routes Data from API
    axios.get(API.GET_FIXED_ROUTES, { params: { town_id } })
      .then(res => {
        const { fixed_routes } = res.data

        // If Fixed Routes Invalid
        if(!fixed_routes?.length) {
          dispatch( setFixedRoutes([]) )

          // Set Is Nav Loading
          dispatch( setIsNavLoading(false) )

          return
        }

        const fixedRouteOptions = sortOptionsAsc(fixed_routes.map(r => ({ ...r, value: r.id, label: r.name })))
        dispatch( setFixedRoutes(fixedRouteOptions) )

        // Load Fixed Routes To Map
        const routeGeojson = transformRowObjectListToGeojson(fixed_routes)
        dispatch( loadFixedRoutesToMap(routeGeojson) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setFixedRoutes([]) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )
      })
  }
}

// Set Is Bkoi Clusters On
export function setIsBkoiClustersOn(isBkoiClustersOn) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_IS_BKOI_CLUSTERS_ON, payload: isBkoiClustersOn })
  }
}

// Set Bkoi Clusters
export function setBkoiClusters(bkoiClusters) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_BKOI_CLUSTERS, payload: bkoiClusters })
  }
}


// Get Bkoi Clusters Data
export function getBkoiClusters(selectedTown) {
  return dispatch => {
    // Set Is Nav Loading
    dispatch( setIsNavLoading(true) )

    // Clear Bkoi Clusters From Map
    dispatch( clearBkoiClustersFromMap() )

    // Get Routes Data from API
    axios.get(API.GET_BKOI_CLUSTERS, { params: { town_id: selectedTown?.id ?? '' } })
      .then(res => {
        const { bkoi_clusters } = res.data

        // If Bkoi Clusters Invalid
        if(!bkoi_clusters?.length) {
          dispatch( setBkoiClusters([]) )

          // Set Is Nav Loading
          dispatch( setIsNavLoading(false) )

          return
        }

        const bkoiClusterOptions = sortOptionsAsc(bkoi_clusters.map(c => ({ ...c, value: c.id, label: c.name })))

        dispatch( setBkoiClusters(bkoiClusterOptions) )

        // Load Bkoi Clusters To Map
        const bkoiClustersGeojson = transformRowObjectListToGeojson(bkoi_clusters)
        dispatch( loadBkoiClustersToMap(bkoiClustersGeojson) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setBkoiClusters([]) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )
      })
  }
}

// Set Is Town Outlets On
export function setIsTownOutletsOn(isTownOutletsOn) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_IS_TOWN_OUTLETS_ON, payload: isTownOutletsOn })
  }
}

// Set Is Bkoi Town Outlets On
export function setIsBkoiTownOutletsOn(isBkoiTownOutletsOn) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_IS_BKOI_TOWN_OUTLETS_ON, payload: isBkoiTownOutletsOn })
  }
}

// Set Is Routes Layer Visible
export function setIsRoutesLayerVisible(isRoutesLayerVisible) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_IS_ROUTES_LAYER_VISIBLE, payload: isRoutesLayerVisible })
  }
}

// Set Is All Distribution Houses On
export function setIsAllDistributionHousesOn(isAllDistributionHousesOn) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_IS_ALL_DISTRIBUTION_HOUSES_ON, payload: isAllDistributionHousesOn })
  }
}

// Set All Distribution Houses
export function setAllDistributionHouses(allDistributionHouses) {
  return dispatch => {
    dispatch({ type: NavActionTypes.SET_ALL_DISTRIBUTION_HOUSES, payload: allDistributionHouses })
  }
}

// Get All Distribution Houses
export function getAllDistributionHouses() {
  return dispatch => {
    // Set Is Nav Loading
    dispatch( setIsNavLoading(true) )

    // Clear All Distribution Houses From Map
    dispatch( clearAllDistributionHousesFromMap() )

    // Get Logged In User
    const user = localStorage.getItem('user') ?? {}
    const params = {
      territory_id: user?.territory_id ?? null,
      area_id: user?.area_id ?? null,
      region_id: user?.region_id ?? null,
      nation_id: user?.nation_id ?? null
    }

    axios.get(API.GET_DH_LIST, { params })
      .then(res => {
        const { dh_list } = res?.data

        // If DH List Invalid
        if(!dh_list?.length) {
          dispatch( setAllDistributionHouses([]) )

          // Set Is Nav Loading
          dispatch( setIsNavLoading(false) )

          return
        }

        // Transform dh_list to Icon Layer Data
        const distributionHouses = transformDistributionHousesToIconData(dh_list)

        // Load All Distribution Houses To Map
        dispatch( loadAllDistributionHousesToMap(distributionHouses) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )
        
      })
      .catch(err => {
        console.error(err)

        dispatch( setAllDistributionHouses([]) )

        // Set Is Nav Loading
        dispatch( setIsNavLoading(false) )
      })
  }
}

///////////////
// Utilities //
///////////////

// Transform Row Object List To Geojson
function transformRowObjectListToGeojson(rowObjectList) {
  const geojson = {
    type: 'FeatureCollection',
    features: []
  }

  if(!rowObjectList?.length) {
    return geojson
  }

  geojson.features = rowObjectList.map(r => {
    const properties = {}
    Object.keys(r).forEach(k => {
      if(k !== 'geometry') {
        properties[ k ] = r[ k ]
      }
    })

    const feature = {
      type: 'Feature',
      properties,
      geometry: r.geometry
    }

    return feature
  })

  return geojson
}

// Transform Distribution Houses To Icon Data
function transformDistributionHousesToIconData(distribution_houses) {
  if(!distribution_houses?.length) {
    return []
  }

  const distributionHouses = distribution_houses.map(d => ({
    ...d,
    icon: 'home'
  }))

  return distributionHouses
}

// Fetch Optimized SR Route
function fetchOptimizedSrRoute(apiParams, outlets) {
  return axios.get(API.GET_OPTIMIZED_ROUTE, { params: apiParams, headers: { 'Content-Type': 'application/json' } })
    .then(res => {
      // Generate LineString GeoJSON object
      const routeGeoJson = convertToGeoJson(res.data)

      // Generate Updated Waypoints Data from response
      const waypoints = generateWaypointsData(res.data, outlets)

      return { routeGeoJson, waypoints }
    })
    .catch(err => {
      throw err
    })
}

// Convert Valhalla Optimized Route Response to a GeoJSON Object
function convertToGeoJson(vhOptimizedRoute) {
  const polyLineGeoJson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          eta: formatDuration(vhOptimizedRoute?.trip?.summary?.time ?? 0),
          distance: `${ vhOptimizedRoute?.trip?.summary?.length ?? 0 }km`
        },
        geometry: {
          type: 'LineString',
          coordinates: vhOptimizedRoute.trip.legs.map(l => decodeVhRouteShape(l.shape)).flat().map((c, i) => ([ ...c, 0, dayjs().unix() + (i * 10) ]))
        }
      }
    ]
  }

  return polyLineGeoJson
}

// Generate Waypoints Data from optimized route response
function generateWaypointsData(vhOptimizedRoute, waypointsData) {
  const len = vhOptimizedRoute.trip.locations.length
  const waypoints = vhOptimizedRoute.trip.locations.slice(1, len-1).map((l, i) => ({
    ...waypointsData[i],
    longitude: l.lon,
    latitude: l.lat,
    waypoint_serial: `${i+1}`
  }))

  return waypoints
}
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box, Grid } from '@mui/material'
import StyledDateRangePicker from '../common/StyledDateRangePicker'
import StyledRadioGroup from '../common/StyledRadioGroup'
import StyledSelect from '../common/StyledSelect'
import StyledButton from '../common/StyledButton'

// Import Actions & Methods
import { setStartDate, setEndDate, getBrands, setSelectedBrand, getSku, setSelectedSku, setSelectedRouteStatsType, setSelectedSr, setSelectedTownStatsType, setSelectedDh, getSrStats, getRouteStats, getDhStats, getTerritoryStats, getAreaStats, getRegionStats, getTownStats, getDhList, getNationStats, getSrList } from '../../redux/actions/statActions'
import { getOutlets, getRoutes, getTowns, getTerritories, getAreas, getRegions, getNations } from '../../redux/actions/navActions'

class StatsSelect extends React.PureComponent {
  componentDidMount() {
    const { dispatch } = this.props

    // Load Brands
    dispatch( getBrands() )

    // Load SKU
    dispatch( getSku() )
  }

  componentDidUpdate(prevProps) {
    const { dispatch, selectedRoute, selectedTown } = this.props

    // If selectedRoute Change in Props
    if(prevProps?.selectedRoute?.value !== selectedRoute?.value && selectedRoute?.value) {
      // Load SR List
      dispatch( getSrList(selectedRoute) )
      dispatch( setSelectedRouteStatsType('route') )
      dispatch( setSelectedTownStatsType('town') )
    }

    // If selectedTown Changes in Props
    if(prevProps?.selectedTown?.value !== selectedTown?.value && selectedTown?.value) {
      // Load DH List
      dispatch( getDhList(selectedTown) )
      dispatch( setSelectedRouteStatsType('route') )
      dispatch( setSelectedTownStatsType('town') )
    }
  }

  // On Date Range Change
  _onDateRangeChange = dateRange => {
    const { dispatch } = this.props
    dispatch( setStartDate(dateRange.startDate) )
    dispatch( setEndDate(dateRange.endDate) )
  }

  // On Brand Change
  _onBrandChange = (e, selectedBrand) => {
    const { dispatch } = this.props
    dispatch( setSelectedBrand(selectedBrand) )
  }

  // On SKU Change
  _onSkuChange = (e, selectedSku) => {
    const { dispatch } = this.props
    dispatch( setSelectedSku(selectedSku) )
  }

  // On Selected Route Stats Type Change
  _onSelectedRouteStatsTypeChange = (e, selectedRouteStatsType) => {
    const { dispatch } = this.props
    dispatch( setSelectedRouteStatsType(selectedRouteStatsType) )
  }

  // On Selected SR Change
  _onSelectedSrChange = (e, selectedSr) => {
    const { dispatch } = this.props
    dispatch( setSelectedSr(selectedSr) )
  }

  // On Selected Town Stats Type Change
  _onSelectedTownStatsTypeChange = (e, selectedTownStatsType) => {
    const { dispatch } = this.props
    dispatch( setSelectedTownStatsType(selectedTownStatsType) )
  }

  // On Selected DH Change
  _onSelectedDhChange = (e, selectedDh) => {
    const { dispatch } = this.props
    dispatch( setSelectedDh(selectedDh) )
  }

  // On Form Submit
  _onSubmit = e => {
    const { dispatch, startDate, endDate, selectedBrand, selectedSku, selectedRoute, selectedTerritory, selectedArea, selectedRegion, selectedRouteStatsType, selectedTownStatsType, selectedSr, selectedDh, selectedTown, selectedNation, lateOrderThresholdTime, fastOrderDuration, isFixedRoutesOn } = this.props
    e.preventDefault()

    const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
    const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''

    // If Route Selected
    if(selectedRoute?.value !== 'None' && selectedRoute?.value !== 'All') {
      if(selectedRouteStatsType === 'sr') {
        dispatch( getSrStats({ start_date: startDate, end_date: endDate, sr_id: selectedSr?.value, route_id: selectedRoute?.value, brand, sku }) )
        return
      }
      
      dispatch( getRouteStats({ start_date: startDate, end_date: endDate, route_id: selectedRoute.id, brand, sku }) )

      // Reload Outlets & Routes To Map
      dispatch(
        getOutlets({ route_id: selectedRoute?.id ?? '', start_date: startDate, end_date: endDate, brand, sku, late_hour: lateOrderThresholdTime, fast_order_duration: fastOrderDuration })
      )
      dispatch( getRoutes(selectedTown, startDate, endDate, brand, sku, isFixedRoutesOn) )

      return
    }

    // If Town Selected
    if(selectedTown?.value !== 'None' && selectedTown?.value !== 'All') {
      if(selectedTownStatsType === 'dh') {
        dispatch( getDhStats({ start_date: startDate, end_date: endDate, dh_id: selectedDh?.value, town_id: selectedTown?.value, brand, sku }) )
        return
      }

      dispatch( getTownStats({ start_date: startDate, end_date: endDate, town_id: selectedTown.id, brand, sku }) )

      // Reload Routes To Map
      dispatch( getRoutes(selectedTown, startDate, endDate, brand, sku, isFixedRoutesOn) )

      return
    }

    // If Territory Selected
    if(selectedTerritory?.value !== 'None' && selectedTerritory?.value !== 'All') {
      dispatch( getTerritoryStats({ start_date: startDate, end_date: endDate, territory_id: selectedTerritory.id, brand, sku }) )

      // Reload Towns To Map
      dispatch( getTowns(selectedTerritory, startDate, endDate, brand, sku) )

      return
    }

    // If Area Selected
    if(selectedArea?.value !== 'None' && selectedArea?.value !== 'All') {
      dispatch( getAreaStats({ start_date: startDate, end_date: endDate, area_id: selectedArea.id, brand, sku }) )
      
      // Reload Territories To Map
      dispatch( getTerritories(selectedArea, startDate, endDate, brand, sku) )

      return
    }

    // If Region Selected
    if(selectedRegion?.value !== 'None' && selectedRegion?.value !== 'All') {
      dispatch( getRegionStats({ start_date: startDate, end_date: endDate, region_id: selectedRegion.id, brand, sku }) )

      // Reload Areas To Map
      dispatch( getAreas(selectedRegion, startDate, endDate, brand, sku) )

      return
    }

    // If Nation Selected
    if(selectedNation?.value !== 'None' && selectedNation?.value !== 'All') {
      dispatch( getNationStats({ start_date: startDate, end_date: endDate, nation_id: selectedNation.id, brand, sku }) )

      // Reload Regions To Map
      dispatch( getRegions(selectedNation, startDate, endDate, brand, sku) )

      return
    }

    // If All or None Nation Selected
    if(selectedNation?.value === 'None' || selectedNation?.value === 'All') {
      // Reload Nations To Map
      dispatch( getNations(startDate, endDate, brand, sku) )

      return
    }
  }

  render() {
    const { isNavLoading, isStatLoading, startDate, endDate, brands, selectedBrand, sku, selectedSku, selectedRoute, selectedRouteStatsType, srList, selectedSr, selectedTownStatsType, selectedDh, dhList, selectedTown } = this.props

    // Filter SKU Options Based on selectedBrand
    const filteredSku = sku?.filter(s => selectedBrand.label === 'All' || s.brand === selectedBrand.label) ?? []

    return (
      <form onSubmit={ this._onSubmit } sx={{ width: '100%' }}>
        <Grid container={ true } spacing={ 1 }>
          <Grid item={ true } xs={ 12 }>
            <StyledDateRangePicker
              value={[ startDate, endDate ]}
              disabled={ isNavLoading || isStatLoading }
              onChange={ this._onDateRangeChange }
            />
          </Grid>

          <Grid item={ true } xs={ 12 }>
            <StyledSelect
              label='Select Brand'
              name='select-brand'
              value={ selectedBrand?.value ?? '' }
              onChange={ this._onBrandChange }
              options={ brands }
              noneOption={ false }
              disabled={ isNavLoading || isStatLoading }
            />
          </Grid>

          {/* <Grid item={ true } xs={ 12 }>
            <StyledSelect
              label='Select SKU'
              name='select-sku'
              value={ selectedSku?.value ?? '' }
              onChange={ this._onSkuChange }
              options={ filteredSku }
              noneOption={ false }
              disabled={ isNavLoading || isStatLoading }
            />
          </Grid> */}

          { selectedRoute && selectedRoute?.value !== 'None' && selectedRoute?.value !== 'All' ?
            (
              <Grid item={ true } xs={ 12 }>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <StyledRadioGroup
                    options={[
                      { value: 'route', label: 'Route' },
                      { value: 'sr', label: 'SO' }
                    ]}
                    value={ selectedRouteStatsType }
                    disabled={ isNavLoading || isStatLoading }
                    onChange={ this._onSelectedRouteStatsTypeChange }
                  />

                  { selectedRouteStatsType === 'sr' &&
                    <StyledSelect
                      label='Select SO'
                      name='selectedSr'
                      value={ selectedSr?.value ?? '' }
                      onChange={ this._onSelectedSrChange }
                      options={ srList }
                      noneOption={ false }
                      allOption={ false }
                      disabled={ isNavLoading || isStatLoading }
                    />
                  }
                </Box>
              </Grid>
            )
            :
            selectedTown && selectedTown?.value !== 'None' && selectedTown?.value !== 'All' ?
            (
              <Grid item={ true } xs={ 12 }>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <StyledRadioGroup
                    options={[
                      { value: 'town', label: 'Town' },
                      { value: 'dh', label: 'DH' }
                    ]}
                    value={ selectedTownStatsType }
                    disabled={ isNavLoading || isStatLoading }
                    onChange={ this._onSelectedTownStatsTypeChange }
                  />

                  { selectedTownStatsType === 'dh' &&
                    <StyledSelect
                      label='Select DH'
                      name='selectedDh'
                      value={ selectedDh?.value ?? '' }
                      onChange={ this._onSelectedDhChange }
                      options={ dhList }
                      noneOption={ false }
                      allOption={ false }
                      disabled={ isNavLoading || isStatLoading }
                    />
                  }
                </Box>
              </Grid>
            )
            :
            (
              null
            )
          }

          <Grid item={ true } xs={ 12 }>
            <StyledButton
              type='submit'
              disabled={ isNavLoading || isStatLoading || !startDate || !endDate }
            >
              { 'Load Stats' }
            </StyledButton>
          </Grid>
        </Grid>
      </form>
    )
  }
}

// Prop Types
StatsSelect.propTypes = {
  isNavLoading: PropTypes.bool,
  isStatLoading: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  brands: PropTypes.array,
  selectedBrand: PropTypes.object,
  sku: PropTypes.array,
  selectedSku: PropTypes.object,
  selectedRoute: PropTypes.object,
  selectedRouteStatsType: PropTypes.string,
  srList: PropTypes.array,
  selectedSr: PropTypes.object,
  selectedTerritory: PropTypes.object,
  selectedTownStatsType: PropTypes.string,
  selectedDh: PropTypes.object,
  dhList: PropTypes.array,
  selectedArea: PropTypes.object,
  selectedRegion: PropTypes.object,
  selectedTown: PropTypes.object,
  selectedNation: PropTypes.object,
  lateOrderThresholdTime: PropTypes.string,
  fastOrderDuration: PropTypes.number,
  isFixedRoutesOn: PropTypes.bool
}

StatsSelect.defaultProps = {
  isNavLoading: false,
  isStatLoading: false,
  startDate: '',
  endDate: '',
  brands: [],
  selectedBrand: null,
  sku: [],
  selectedSku: null,
  selectedRoute: null,
  selectedRouteStatsType: '',
  srList: [],
  selectedSr: null,
  selectedTerritory: null,
  selectedTownStatsType: '',
  selectedDh: null,
  dhList: [],
  selectedArea: null,
  selectedRegion: null,
  selectedTown: null,
  selectedNation: null,
  lateOrderThresholdTime: '17:00:00',
  fastOrderDuration: 120,
  isFixedRoutesOn: false
}

const mapStateToProps = state => ({
  isNavLoading: state?.nav?.isNavLoading ?? false,
  isStatLoading: state?.stat?.isStatLoading ?? false,
  startDate: state?.stat?.startDate ?? '',
  endDate: state?.stat?.endDate ?? '',
  brands: state?.stat?.brands ?? [],
  selectedBrand: state?.stat?.selectedBrand ?? null,
  sku: state?.stat?.sku ?? [],
  selectedSku: state?.stat?.selectedSku ?? null,
  selectedRoute: state?.nav?.selectedRoute ?? null,
  selectedRouteStatsType: state?.stat?.selectedRouteStatsType ?? '',
  srList: state?.stat?.srList ?? [],
  selectedSr: state?.stat?.selectedSr ?? null,
  selectedTerritory: state?.nav?.selectedTerritory ?? null,
  selectedTownStatsType: state?.stat?.selectedTownStatsType ?? '',
  selectedDh: state?.stat?.selectedDh ?? null,
  dhList: state?.stat?.dhList ?? [],
  selectedArea: state?.nav?.selectedArea ?? null,
  selectedRegion: state?.nav?.selectedRegion ?? null,
  selectedTown: state?.nav?.selectedTown ?? null,
  selectedNation: state?.nav?.selectedNation ?? null,
  lateOrderThresholdTime: state?.configs?.lateOrderThresholdTime ?? '17:00:00',
  fastOrderDuration: state?.configs?.fastOrderDuration ?? 120,
  isFixedRoutesOn: state?.nav?.isFixedRoutesOn ?? false
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(StatsSelect)
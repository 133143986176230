import { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { points as turfPoints, center as turfCenter } from '@turf/turf'

// Import Components
import { Box, Typography, Slider } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import ExportMenu from '../common/ExportMenu'

// Import Styles
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

// Import Actions & Methods
import { getMinMaxValue, exportObjectsAsCsv, exportObjectsAsXlsx, reverseGeo, formatDuration, formatDistance } from '../../utils/utils'

const SrOrdersTable = ({ srOrders, srOrderColumns, srOrderClusters, selectedRoute, selectedSr }) => {
  const [flex, setFlex] = useState(1)
  const [ srOrdersData, setSrOrdersData ] = useState(srOrders)
  const [ orderDistanceRange, setOrderDistanceRange ] = useState([ 0, 0 ])
  const [ orderDistanceRangeMarks, setOrderDistanceRangeMarks ] = useState([])

  useEffect(() => {
    setSrOrdersData(srOrders)

    if(srOrders?.length) {
      // Set Order Distance Range Marks
      const newMinMaxOrderDistance = getMinMaxValue(srOrders, 'distance_from_outlet_m', true)
      const newOrderDistanceRangeMarks = _getOrderDistanceRangeMarks(newMinMaxOrderDistance, Math.ceil((newMinMaxOrderDistance[1] - newMinMaxOrderDistance[0]) / 6))
      setOrderDistanceRange([ 0, newMinMaxOrderDistance[ 1 ] ])
      setOrderDistanceRangeMarks(newOrderDistanceRangeMarks)
    }
  }, [ srOrders ])

  // Refs
  const containerRef = useRef()
  const _gridRef = useRef()

  // Auto Size All Columns
  const _autoSizeAllColumns = useCallback(skipHeader => {
    const allColumnIds = []
    _gridRef.current.columnApi.getAllColumns().forEach(column => {
      allColumnIds.push(column.getId())
    })
    _gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader)
  }, [])

  // On Grid Ready
  const _onGridReady = () => {
    // Auto Size Column Widths
    _autoSizeAllColumns(false)

    // Set Height
    const _height = containerRef?.current?.clientHeight ?? 0
    if(_height < 400) {
      setFlex('unset')
    }
  }

  // On Order Distance Range Change
  const _onOrderDistanceRangeChange = (e, newOrderDistanceRange) => {
    setOrderDistanceRange(newOrderDistanceRange)
  }

  // On Order Distance Range Committed
  const _onOrderDistanceRangeCommitted = (e, orderDistanceRange) => {
    const filteredSrOrdersData = _filterSrOrdersByOrderDistanceRange(orderDistanceRange)
    setSrOrdersData(filteredSrOrdersData)
  }

  // Get Order Distance Range Marks
  const _getOrderDistanceRangeMarks = (minMaxRangeValue, step) => {
    const marks = [{ value: minMaxRangeValue[ 0 ], label: formatDistance(minMaxRangeValue[ 0 ]) }]
    for(let i = minMaxRangeValue[ 0 ] + step; i > minMaxRangeValue[ 0 ] && Math.ceil(i) < minMaxRangeValue[ 1 ]; i += step) {
      marks.push({
        value: Math.ceil(i),
        label: formatDistance(Math.ceil(i))
      })
    }

    marks.push({ value: minMaxRangeValue[ 1 ], label: formatDistance(minMaxRangeValue[ 1 ]) })

    return marks
  }

  // Filter Sr Orders By Order Distance Range
  const _filterSrOrdersByOrderDistanceRange = orderDistanceRange => {
    return srOrders?.filter(so =>
      so?.distance_from_outlet_m >= orderDistanceRange[0] && so?.distance_from_outlet_m <= orderDistanceRange[1]
    ) ?? []
  }

  // Order Distance Value Label Format
  const _orderDistanceValueLabelFormat = value => {
    return formatDistance(value)
  }

  // On CSV Export
  const _onCsvExport = () => {
    const fileName = 'sr_orders'

    // Export CSV
    exportObjectsAsCsv(srOrdersData, fileName)
  }

  // On XLSX Export
  const _onXlsxExport = () => {
    const fileName = 'sr_orders'

    // Export XLSX
    exportObjectsAsXlsx(srOrdersData, fileName)
  }

  // On SR Order Clusters CSV Export
  const _onSrOrderClustersCsvExport = () => {
    // Export Cluster Stats
    const promises = srOrderClusters.filter(sc => sc?.outlets?.length > 1).map(c => _getSalesLocationStats(c))
    Promise.allSettled(promises)
      .then(results => {
        const salesLocations = results.map(r => r?.value ?? r?.reason ?? {})

        // Export CSV
        const clustersFileName = 'sales_locations'
        exportObjectsAsCsv(salesLocations, clustersFileName)

        // Export Sales Location Orders
        const orders = []
        srOrderClusters.filter(sc => sc?.outlets?.length > 1).forEach(c => {
          const clusterStats = salesLocations?.find(sl => sl?.cluster_id === c?.cluster_id) ?? {}

          c.orders.forEach(or => {
            let order = {
              cluster_id: c.cluster_id,
              area: clusterStats?.area ?? 'N/A',
              sub_area: clusterStats?.sub_area ?? 'N/A',
              super_sub_area: clusterStats?.super_sub_area ?? 'N/A',
              route_name: clusterStats?.route_name ?? 'N/A',
              ...or
            }
            const outlet = c.outlets.find(o => o.id === order.outlet_id) ?? {}
            order = { ...order, ...outlet }

            delete order.id

            orders.push(order)
          })
        })

        // Export CSV
        const salesLocationOrdersFileName = 'sales_location_orders'
        exportObjectsAsCsv(orders, salesLocationOrdersFileName)

      })
      .catch(err => {
        console.error(err)
      })
  }

  // On SR Order Clusters XLSX Export
  const _onSrOrderClustersXlsxExport = () => {
    // Export Cluster Stats
    const promises = srOrderClusters.filter(sc => sc?.outlets?.length > 1).map(c => _getSalesLocationStats(c))
    Promise.allSettled(promises)
      .then(results => {
        const salesLocations = results.map(r => r?.value ?? r?.reason ?? {})

        // Export XLSX
        const clustersFileName = 'sales_locations'
        exportObjectsAsXlsx(salesLocations, clustersFileName)

        // Export Sales Location Orders
        const orders = []
        srOrderClusters.filter(sc => sc?.outlets?.length > 1).forEach(c => {
          const clusterStats = salesLocations?.find(sl => sl?.cluster_id === c?.cluster_id) ?? {}

          c.orders.forEach(or => {
            let order = {
              cluster_id: c.cluster_id,
              area: clusterStats?.area ?? 'N/A',
              sub_area: clusterStats?.sub_area ?? 'N/A',
              super_sub_area: clusterStats?.super_sub_area ?? 'N/A',
              route_name: clusterStats?.route_name ?? 'N/A',
              ...or
            }
            const outlet = c.outlets.find(o => o.id === order.outlet_id) ?? {}
            order = { ...order, ...outlet }

            delete order.id

            orders.push(order)
          })
        })

        // Export XLSX
        const salesLocationOrdersFileName = 'sales_location_orders'
        exportObjectsAsXlsx(orders, salesLocationOrdersFileName)

      })
      .catch(err => {
        console.error(err)
      })
  }

  // Get Sales Location Stats
  const _getSalesLocationStats = cluster => {
    const points = cluster.orders.map(o => ([ o.order_longitude, o.order_latitude ]))
    const features = turfPoints(points)

    const center = turfCenter(features)

    return reverseGeo({ longitude: center.geometry.coordinates[0], latitude: center.geometry.coordinates[1] })
      .then(data => {
        const clusterId = cluster.cluster_id
        const area = data.area ? data.area : 'N/A'
        const subArea = data.sub_area ? data.sub_area : 'N/A'
        const superSubArea = data.super_sub_area ? data.super_sub_area : 'N/A'
        const routeName = selectedRoute?.label ?? 'N/A'
        const srName = selectedSr?.label ?? 'N/A'
        const totalOrders = cluster?.orders?.length ?? 0
        const totalOutlets = cluster?.outlets?.length ?? 0
        let totalGrossQuantity = 0
        let totalGrossValue = 0

        cluster.orders.forEach(or => {
          totalGrossQuantity += or.quantity
          totalGrossValue += or.gross_value
        })

        return {
          cluster_id: clusterId,
          area,
          sub_area: subArea,
          super_sub_area: superSubArea,
          route_name: routeName,
          sr_name: srName,
          total_orders: totalOrders,
          total_outlets: totalOutlets,
          total_gross_quantity: totalGrossQuantity,
          total_gross_value: totalGrossValue
        }
      })
      .catch(err => {
        console.error(err)

        let totalGrossQuantity = 0
        let totalGrossValue = 0
        cluster.orders.forEach(or => {
          totalGrossQuantity += or.quantity
          totalGrossValue += or.gross_value
        })

        Promise.reject({
          cluster_id: cluster.cluster_id,
          area: 'N/A',
          sub_area: 'N/A',
          super_sub_area: 'N/A',
          route_name: selectedRoute?.label ?? 'N/A',
          sr_name: selectedSr?.label ?? 'N/A',
          total_orders: cluster?.orders?.length ?? 0,
          total_outlets: cluster?.outlets?.length ?? 0,
          total_gross_quantity: totalGrossQuantity,
          total_gross_value: totalGrossValue
        })
      })
  }

  return (
    <Box sx={{ width: '100%', minHeight: '400px', height: '400px', flex, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
      <Typography variant='caption' color='text.secondary'>{ 'SO Orders' }</Typography>
      { srOrders?.length > 0 &&
        <Box padding='0.8rem' width='100%'>
          <Typography
            align='left'
            noWrap={ true }
            variant='caption'
            color='textSecondary'
          >
            { 'Filter by Distance' }
          </Typography>
          <Slider
            name='order-distance-range'
            size='small'
            marks={ orderDistanceRangeMarks }
            max={ orderDistanceRangeMarks?.length > 0 ? orderDistanceRangeMarks[ orderDistanceRangeMarks.length - 1 ].value : 0 }
            min={ orderDistanceRangeMarks?.length > 0 ? orderDistanceRangeMarks[ 0 ].value : 0 }
            step={ 50 }
            value={ orderDistanceRange }
            valueLabelDisplay='auto'
            valueLabelFormat={ _orderDistanceValueLabelFormat }
            onChange={ _onOrderDistanceRangeChange }
            onChangeCommitted={ _onOrderDistanceRangeCommitted }
            sx={{
              margin: 0,
              marginTop: '-0.5rem',
              '& .MuiSlider-markLabel': {
                marginTop: '-0.8rem'
              }
            }}
          />
        </Box>
      }
      
      <Box
        ref={ containerRef }
        className='ag-theme-alpine'
        sx={{ width: '100%', height: '100%' }}
      >
        <AgGridReact
          ref={ _gridRef }
          rowData={ srOrdersData }
          columnDefs={ srOrderColumns }
          onGridReady={ _onGridReady }
        />
      </Box>

      <Box sx={{ p: '0.5rem', pr: '0px', ml: 'auto', display: 'flex', justifyContent: 'flex-end', columnGap: '0.5rem' }}>
        <ExportMenu
          buttonId='sr-orders-by-sales-locations-export-button'
          buttonText='Export Sales Locations'
          menuId='sr-orders-by-sales-locations-export-menu'
          disabled={ srOrdersData?.length === 0 }
          onExportCsv={ _onSrOrderClustersCsvExport }
          onExportXlsx={ _onSrOrderClustersXlsxExport }
        />

        <ExportMenu
          buttonId='sr-orders-export-button'
          buttonText='Export Orders'
          menuId='sr-orders-export-menu'
          disabled={ srOrdersData?.length === 0 }
          onExportCsv={ _onCsvExport }
          onExportXlsx={ _onXlsxExport }
        />
      </Box>
    </Box>
  )
}

// Prop Types
SrOrdersTable.propTypes = {
  srOrders: PropTypes.array,
  srOrderColumns: PropTypes.array,
  srOrderClusters: PropTypes.array,
  selectedRoute: PropTypes.object,
  selectedSr: PropTypes.object
}

SrOrdersTable.defaultProps = {
  srOrders: [],
  srOrderColumns: [],
  srOrderClusters: [],
  selectedRoute: null,
  selectedSr: null
}

const mapStateToProps = state => ({
  srOrders: state?.stat?.srOrders?.map(so => ({ ...so, duration: formatDuration(so.duration) })) ?? [],
  srOrderColumns: state?.stat?.srOrderColumns ?? [],
  srOrderClusters: state?.stat?.srOrderClusters ?? [],
  selectedRoute: state?.nav?.selectedRoute ?? null,
  selectedSr: state?.stat?.selectedSr ?? null
})

export default connect(mapStateToProps)(SrOrdersTable)
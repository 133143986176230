import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { Box, Menu, MenuItem } from '@mui/material'
import { FileDownload } from '@mui/icons-material'
import StyledButton from './StyledButton'

class ExportMenu extends React.PureComponent {
  state = {
    exportMenuAnchEl: null
  }

  // On Export Menu Open
  _onExportMenuOpen = e => {
    this.setState({ exportMenuAnchEl: e.currentTarget })
  }

  // On Export Menu Close
  _onExportMenuClose = () => {
    this.setState({ exportMenuAnchEl: null })
  }
  
  render() {
    const { disabled, buttonId, buttonText, menuId, onExportCsv, onExportXlsx } = this.props
    const { exportMenuAnchEl } = this.state
    const isExportMenuOpen = Boolean(exportMenuAnchEl)

    return (
      <Box>
        <StyledButton
          variant='outlined'
          id={ buttonId }
          aria-controls={ isExportMenuOpen ? menuId : undefined }
          aria-haspopup='true'
          aria-expanded={ isExportMenuOpen ? 'true' : undefined }
          fullWidth={ false }
          endIcon={ <FileDownload fontSize='small' /> }
          disabled={ disabled }
          onClick={ this._onExportMenuOpen }
        >
          { buttonText }
        </StyledButton>
        <Menu
          id={ menuId }
          anchorEl={ exportMenuAnchEl }
          open={ isExportMenuOpen }
          onClose={ this._onExportMenuClose }
          MenuListProps={{ 'aria-labelledby': buttonId }}
        >
          <MenuItem
            dense={ true }
            sx={{ fontSize: '0.8rem' }}
            onClick={ onExportCsv }
          >
            { '.CSV' }
          </MenuItem>
          <MenuItem
            dense={ true }
            sx={{ fontSize: '0.8rem' }}
            onClick={ onExportXlsx }
          >
            { '.XLSX' }
          </MenuItem>
        </Menu>
      </Box>
    )
  }
}

// Prop Types
ExportMenu.propTypes = {
  buttonId: PropTypes.string,
  buttonText: PropTypes.string,
  menuId: PropTypes.string,
  disabled: PropTypes.bool,
  onExportCsv: PropTypes.func,
  onExportXlsx: PropTypes.func
}

ExportMenu.defaultProps = {
  buttonId: 'export-button',
  buttonText: 'Export',
  menuId: 'export-menu',
  disabled: false,
  onExportCsv: () => null,
  onExportXlsx: () => null
}

export default ExportMenu
import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { Card, CardHeader, CardContent, Typography, IconButton, Tooltip } from '@mui/material'
import { HelpOutline } from '@mui/icons-material'

class StyledCard extends React.PureComponent {
  // Transform Value
  _transformValue = value => {
    let valueString = String(value)
    const numberMatch = valueString.match(/\d+/g)

    if(numberMatch?.length) {
      const formattedNumber = new Intl.NumberFormat('en-IN').format(Number(numberMatch[0]))
      valueString = valueString.replace(numberMatch[0], formattedNumber)
    }

    return valueString
  }

  render() {
    const { label, value, help, helpText } = this.props

    return (
      <Card variant='outlined' sx={ theme => ({ padding: theme.spacing(1) }) }>
        <CardHeader
          subheader={ label }
          subheaderTypographyProps={{
            sx: {
              fontSize: '0.7rem'
            }
          }}
          action={
            help ?
            (
              <Tooltip title={ helpText } arrow={ true }>
                <IconButton
                  disableRipple={ true }
                  disableFocusRipple={ true }
                  size='small'
                  sx={{ pt: 0 }}
                >
                  <HelpOutline fontSize='small' />
                </IconButton>
              </Tooltip>
            )
            :
            (
              null
            )
          }
          sx={{ padding: 0 }}
        />
        <CardContent sx={{ padding: '0px !important' }}>
          <Typography variant='body1' color='primary'>{ this._transformValue(value) }</Typography>
        </CardContent>
      </Card>
    )
  }
}

// Prop Types
StyledCard.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  help: PropTypes.bool,
  helpText: PropTypes.string
}

StyledCard.defaultProps = {
  label: '',
  value: '-',
  help: true,
  helpText: ''
}

export default StyledCard
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _isEqual from 'fast-deep-equal'

// Import Components
import { Box, TextField, List, ListItem, ListItemText, ListItemAvatar, Avatar, Badge, InputAdornment } from '@mui/material'
import { Search } from '@mui/icons-material'

// Import Actions & Methods
import { updateMap } from 'kepler.gl/actions'

class UserList extends React.PureComponent {
  state = {
    users: [],
    userSearch: ''
  }

  componentDidMount() {
    // Initial Sort Users By Updated At
    this._updateUsers()
  }

  componentDidUpdate(prevProps) {
    const { users } = this.props

    // If users changes in props
    if(!_isEqual(prevProps.users, users)) {
      // Sort Users By Updated At
      this._updateUsers()
    }
  }

  // On Change
  _onChange = event => {
    this.setState({ [ event.target.name ]: event.target.value })
  }

  // Sort Users By Last Updated
  _sortByLastUpdated = users => {
    const sortedUsers = users.sort((u1, u2) => {
      const updatedAt1 = u1.updated_at ? u1.updated_at.trim() : ''
      const updatedAt2 = u2.updated_at ? u2.updated_at.trim() : ''

      if (updatedAt1 > updatedAt2) {
        return -1
      }

      if (updatedAt1 < updatedAt2) {
        return 1
      }

      return 0
    })

    return sortedUsers
  }

  // Filter User List By Search
  _filterUserList = (users, userSearch) => {
    if(!userSearch) {
      return users
    }

    const filteredUsers = users.filter(u => {
      const _userSearch = userSearch.toLowerCase().trim()
      const _userName = u.name ? u.name.toLowerCase().trim() : ''

      return _userName.includes(_userSearch)
    })

    return filteredUsers
  }

  // On User Click
  _onUserClick = selectedUser => {
    const { dispatch } = this.props
    
    // Update Map Center
    dispatch( updateMap({ longitude: selectedUser.longitude, latitude: selectedUser.latitude, zoom: 18 }) )
  }

  // Update Users Periodically
  _updateUsers = () => {
    const { users } = this.props
    const _users = this._sortByLastUpdated(users)
    this.setState({ users: _users })
  }

  render() {
    const { users } = this.state
    const { userSearch } = this.state
    const filteredUsers = this._filterUserList(users, userSearch)

    return (
      <Box>
        <TextField
          name='userSearch'
          fullWidth={ true }
          variant='outlined'
          label='Search User'
          margin='dense'
          size='small'
          value={ userSearch }
          onChange={ this._onChange }
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Search style={{ color: 'rgba(80, 80, 80, 0.6)' }}/>
              </InputAdornment>
            )
          }}
        />

        <List
          dense={ true }
          disablePadding={ true }
          style={{
            ...listStyles,
            overflowY: 'auto'
          }}
        >
          {
            filteredUsers.length <= 0 ?
            (
              <ListItem
                dense={ true }
                disableGutters={ true }
                style={ listItemStyles }
              >
                <ListItemText
                  primary='No user found.'
                  primaryTypographyProps={{
                    style: {
                      color: '#505050',
                      fontSize: '12px'
                    }
                  }}
                />
              </ListItem>
            )
            :
            filteredUsers.map((u, i) =>
              <ListItem
                key={ i }
                button={ true }
                dense={ true }
                disableGutters={ true }
                style={ listItemStyles }
                onClick={ () => this._onUserClick(u) }
              >
                <ListItemAvatar style={{ margin: 0, display: 'flex' }}>
                  <Badge
                    overlap='circular'
                    anchorOrigin={{
                      horizontal: 'right',
                      vertical: 'bottom'
                    }}
                    variant='dot'
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: u?.active_position === 'Inside Route/Online' ? '#228b22' : '#dc143c',
                        color: u?.active_position === 'Inside Route/Online' ? '#228b22' : '#dc143c',
                        boxShadow: `0 0 0 2px #f0f0f0`,
                        '&::after': {
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          animation: 'ripple 1.2s infinite ease-in-out',
                          border: '1px solid currentColor',
                          content: '""',
                        }
                      },
                      '@keyframes ripple': {
                        '0%': {
                          transform: 'scale(0.8)',
                          opacity: 1
                        },
                        '100%': {
                          transform: 'scale(2.4)',
                          opacity: 0
                        }
                      }
                    }}
                  >
                    <Avatar
                      style={{
                        margin: 0,
                        width: '36px',
                        height: '36px'
                      }}
                    />
                  </Badge>
                </ListItemAvatar>
                <ListItemText
                  primary={ `${ i+1 }. ${ u.name }` }
                  secondary={ `Last Updated: ${ u.updated_at }` }
                  primaryTypographyProps={{
                    style: {
                      color: '#505050',
                      fontSize: '12px'
                    }
                  }}
                  secondaryTypographyProps={{
                    style: {
                      color: '#808080',
                      fontSize: '11px'
                    }
                  }}
                />
              </ListItem>
            )
          }
        </List>
      </Box>
    )
  }
}

// JSS Styles
const listStyles = {
  boxSizing: 'border-box',
  width: '100%',
  maxHeight: '400px',
  border: '1px solid rgba(80, 80, 80, 0.4)',
  borderRadius: '4px'
}

const listItemStyles = {
  padding: '0px 8px',
  width: '100%'
}

// Prop Types
UserList.propTypes = {
  users: PropTypes.array,
  dispatch: PropTypes.func
}

UserList.defaultProps = {
  users: [],
  dispatch: () => null
}

const mapStateToProps = state => ({
  users: state?.trace?.traceUsers ?? []
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(UserList)
import { injectComponents, ModalContainerFactory, SidePanelFactory, LayerHoverInfoFactory } from 'kepler.gl/components'
// import CustomLayerHoverInfo from './CustomLayerHoverInfo'

// Define null factory to not render any unneсessary components
const NullComponent = () => null
const NullComponentFactory = () => NullComponent
const CustomLayerHoverInfoFactory = () => NullComponent

const KeplerGl = injectComponents([
  [ ModalContainerFactory, NullComponentFactory ],
  [ SidePanelFactory, NullComponentFactory ],
  [ LayerHoverInfoFactory, CustomLayerHoverInfoFactory ]
])

export default KeplerGl
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box } from '@mui/material'
import StyledSwitch from '../common/StyledSwitch'

// Import Actions & Methods
import { getBkoiOutlets, setBkoiOutlets, setIsBkoiOutletsOn } from '../../redux/actions/navActions'
import { clearBkoiOutletsFromMap } from '../../redux/actions/mapActions'

const BkoiOutletsControl = ({ dispatch, selectedRoute, isBkoiOutletsOn }) => {
  useEffect(() => {
    return () => {
      dispatch( setIsBkoiOutletsOn(false) )

      // Clear Bkoi Outlets
      dispatch( setBkoiOutlets([]) )
      dispatch( clearBkoiOutletsFromMap() )
    }
  }, [ dispatch ])

  useEffect(() => {
    if(isBkoiOutletsOn) {
      if(selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None') {
        // Load Barikoi Outlets
        dispatch( getBkoiOutlets({ route_id: selectedRoute?.id ?? '' }) )
      }

    } else {
      // Clear Bkoi Outlets
      dispatch( setBkoiOutlets([]) )
      dispatch( clearBkoiOutletsFromMap() )
    }
  }, [ dispatch, isBkoiOutletsOn, selectedRoute ])

  const _onBkoiOutletsControlChange = (e, value) => {
    dispatch( setIsBkoiOutletsOn(value) )
  }

  return (
    <Box>
      <StyledSwitch
        label='Bkoi Outlets'
        checked={ isBkoiOutletsOn }
        value={ isBkoiOutletsOn }
        onChange={ _onBkoiOutletsControlChange }
      />
    </Box>
  )
}

// Prop Types
BkoiOutletsControl.propTypes = {
  selectedRoute: PropTypes.object,
  isBkoiOutletsOn: PropTypes.bool,
  dispatch: PropTypes.func
}

BkoiOutletsControl.defaultProps = {
  selectedRoute: null,
  isBkoiOutletsOn: false,
  dispatch: () => null
}

const mapStateToProps = state => ({
  selectedRoute: state?.nav?.selectedRoute ?? null,
  isBkoiOutletsOn: state?.nav?.isBkoiOutletsOn ?? false,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(BkoiOutletsControl)
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box, IconButton, Divider } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'

// Import Actions & Methods
import { setIsLeftNavOpen } from '../../redux/actions/navActions'

class LeftNavHeader extends React.PureComponent {
  // On Close
  _onClose = () => {
    const { dispatch } = this.props
    dispatch( setIsLeftNavOpen(false) )
  }

  render() {
    return (
      <React.Fragment>
        <Box sx={ drawerHeaderStyles }>
          <IconButton size='small' onClick={ this._onClose }>
            <ChevronLeft fontSize='small' />
          </IconButton>
        </Box>
        <Divider />
      </React.Fragment>
    )
  }
}

// Styles
const drawerHeaderStyles = theme => ({
  ...theme.mixins.toolbar,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end'
})

// Prop Types
LeftNavHeader.propTypes = {
  dispatch: PropTypes.func
}

LeftNavHeader.defaultProps = {
  dispatch: () => null
}

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(null, mapDispatchToProps)(LeftNavHeader)
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import _isEqual from 'fast-deep-equal'
import dayjs from 'dayjs'

// Import Components
import { styled } from '@mui/material/styles'
import { Box, Grid, LinearProgress } from '@mui/material'
import TopNav from '../TopNav/TopNav'
import LeftNav from '../LeftNav/LeftNav'
import MapGL from '../Map/MapGL'
import RightPanel from '../RightPanel/RightPanel'
import SelectColorBy from '../Map/SelectColorBy'
import TraceControl from '../Map/TraceControl'
import RouteControl from '../Map/RouteControl'
import OrdersLayerControl from '../Map/OrdersLayerControl'
import Filters from '../Map/Filters'
import ExportOutlets from '../Map/ExportOutlets'
import ExportSo from '../Map/ExportSo'
import BkoiOutletsControl from '../Map/BkoiOutletsControl'
import FixedOutletsControl from '../Map/FixedOutletsControl'
import FixedRoutesControl from '../Map/FixedRoutesControl'
import BkoiClustersControl from '../Map/BkoiClustersControl'
import TownOutletsControl from '../Map/TownOutletsControl'
import BkoiTownOutletsControl from '../Map/BkoiTownOutletsControl'
import RoutesLayerVisibilityControl from '../Map/RoutesLayerVisibilityControl'
import AllDistributionHousesControl from '../Map/AllDistributionHousesControl'

// Import Actions & Methods
import { setFeatureControls } from '../../redux/actions/featureControlActions'
import { activateSocketNotifications, deactivateSocketNotifications, setSrPositionData, setSrOrderDelayData, triggerSrPositionAlertEvent } from '../../redux/actions/socketActions'

class UnileverDashboard extends React.PureComponent {
  componentDidMount() {
    const { dispatch } = this.props

    let user = localStorage.getItem('user')
    if(user) {
      user = JSON.parse(user)
    }
    
    // Feature Control based on user levels
    dispatch( setFeatureControls(user) )

    // Activate Socket Notifications
    dispatch( activateSocketNotifications() )
  }

  componentDidUpdate(prevProps) {
    const { dispatch, srPositionData, srOrderDelayData, enqueueSnackbar, traceUsers } = this.props

    //  If `traceUsers` changes in props
    if(!_isEqual(prevProps.traceUsers, traceUsers)) {
      traceUsers.forEach(tu => {
        const prevTu = prevProps.traceUsers.find(ptu => ptu.user_id === tu.user_id) ?? null
      
        if((tu?.position && prevTu?.position) && tu?.position !== prevTu?.position) {
          const message = tu?.position === 'Outside Route' ?
            `SO ${ tu.name ?? 'N/A' } left ${ tu.route_name ?? 'N/A' } at ${ dayjs(tu.updated_at).format('hh:mm:ss A') }` :
            `SO ${ tu.name ?? 'N/A' } entered ${ tu.route_name ?? 'N/A' } at ${ dayjs(tu.updated_at).format('hh:mm:ss A') }`
  
          if(message) {
            dispatch( triggerSrPositionAlertEvent({ message, position: tu.position }) )
          }
        }
      })
    }

    // If `srPositionData` changes in props
    if(!_isEqual(prevProps.srPositionData, srPositionData) && srPositionData) {
      if(srPositionData?.message && srPositionData?.position) {
        enqueueSnackbar(
          srPositionData?.message ?? '',
          {
            variant: srPositionData?.position === 'Outside Route' ? 'warning' : 'success',
            persist: srPositionData?.position === 'Outside Route' ? true : false,
            preventDuplicate: true,
            anchorOrigin: { horizontal: 'center', vertical: 'top' }
          }
        )

        // Reset `srPositionData`
        dispatch( setSrPositionData(null) )
      }
    }

    // If `srOrderDelayData` changes in props
    if(!_isEqual(prevProps.srOrderDelayData, srOrderDelayData) && srOrderDelayData) {
      if(srOrderDelayData?.message) {
        enqueueSnackbar(
          srOrderDelayData?.message ?? '',
          {
            variant: 'warning',
            persist: true,
            preventDuplicate: true,
            anchorOrigin: { horizontal: 'center', vertical: 'top' }
          }
        )

        // Reset `srOrderDelayData`
        dispatch( setSrOrderDelayData(null) )
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props

    // Deactivate Socket Connections
    dispatch( deactivateSocketNotifications() )
  }

  render() {
    const { isNavLoading, isStatLoading, isTraceLoading, isLeftNavOpen, selectedRoute, srOrders, selectedRouteStatsType, selectedSr, srVisitedOutlets, selectedTown, outlets, isTraceOn, traceUsers } = this.props

    return (
      <Box sx={ containerStyles }>
        <TopNav />

        <Box sx={{ width: '100%', flex: 1 }}>
          { (isNavLoading || isStatLoading || isTraceLoading) &&
            <LinearProgress sx={{ width: '100%', float: 'left' }} />
          }

          <LeftNav />

          <StyledBox open={ isLeftNavOpen }>
            <Grid container={ true } spacing={ 1 } sx={{ height: '100%' }}>
              <Grid item={ true } xs={ 12 } sx={{ pt: '8px !important' }}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Box sx={ selectColorByContainerStyles }>
                    <SelectColorBy />
                  </Box>

                  <Box sx={{ marginX: '0.5rem' }}>
                    <Filters />
                  </Box>

                  { (selectedTown?.value !== 'All' && selectedTown?.value !== 'None') &&
                    <Box sx={{ marginX: '0.5rem' }}>
                      <ExportOutlets disabled={ !outlets?.length } />
                    </Box>
                  }

                  { isTraceOn &&
                    <Box sx={{ marginX: '0.5rem' }}>
                      <ExportSo disabled={ !traceUsers?.length } />
                    </Box>
                  }
                  
                  <Box ml='auto' px='4px' overflow='auto' whiteSpace='nowrap' display='flex' columnGap='8px'>
                    { ((selectedTown?.value !== 'All' && selectedTown?.value !== 'None') && (selectedRoute?.value === 'All' || selectedRoute?.value === 'None')) &&
                      <React.Fragment>
                        <RoutesLayerVisibilityControl />
                        <BkoiClustersControl />
                        <TownOutletsControl />
                        <BkoiTownOutletsControl />
                      </React.Fragment>
                    }

                    { (selectedTown?.value !== 'All' && selectedTown?.value !== 'None') &&
                      <FixedRoutesControl />
                    }

                    { (selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None' && selectedRoute?.value) &&
                      <React.Fragment>
                        <BkoiOutletsControl />
                        <FixedOutletsControl />
                      </React.Fragment>
                    }

                    { (selectedRoute?.value !== 'None' && selectedRoute?.value !== 'All' && selectedRouteStatsType === 'sr' && selectedSr?.value !== 'None' && selectedSr?.value !== 'All' && (srOrders.length > 0 || srVisitedOutlets.length > 0)) &&
                      <OrdersLayerControl />
                    }

                    { (selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None' && selectedRoute?.value) &&
                      <RouteControl />
                    }
                    
                    <TraceControl />
                    <AllDistributionHousesControl />
                  </Box>
                </Box>
              </Grid>
              <Grid item={ true } xs={ 8 }>
                <MapGL />
              </Grid>
              <Grid item={ true } xs={ 4 }>
                <RightPanel />
              </Grid>
            </Grid>
          </StyledBox>
        </Box>
      </Box>
    )
  }
}

// Styles
const StyledBox = styled(Box, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  transition: theme.transitions.create([ 'margin', 'width' ], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${ 320 }px)`,
    marginLeft: `${ 320 }px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  }),
  padding: theme.spacing(2),
  height: '100%'
}))

const containerStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center'
}

const selectColorByContainerStyles = {
  width: '180px'
}

// Prop Types
UnileverDashboard.propTypes = {
  isNavLoading: PropTypes.bool,
  isStatLoading: PropTypes.bool,
  isTraceLoading: PropTypes.bool,
  isLeftNavOpen: PropTypes.bool,
  selectedRoute: PropTypes.object,
  srOrders: PropTypes.array,
  selectedRouteStatsType: PropTypes.string,
  selectedSr: PropTypes.object,
  srVisitedOutlets: PropTypes.array,
  srPositionData: PropTypes.object,
  srOrderDelayData: PropTypes.object,
  selectedTown: PropTypes.object,
  traceUsers: PropTypes.array,
  isTraceOn: PropTypes.bool,
  dispatch: PropTypes.func
}

UnileverDashboard.defaultProps = {
  isNavLoading: false,
  isStatLoading: false,
  isTraceLoading: false,
  isLeftNavOpen: false,
  selectedRoute: null,
  srOrders: [],
  selectedRouteStatsType: '',
  selectedSr: null,
  srVisitedOutlets: [],
  srPositionData: null,
  srOrderDelayData: null,
  selectedTown: null,
  traceUsers: [],
  isTraceOn: false,
  dispatch: () => null
}

const mapStateToProps = state => ({
  isNavLoading: state?.nav?.isNavLoading ?? false,
  isStatLoading: state?.stat?.isStatLoading ?? false,
  isTraceLoading: state?.trace?.isTraceLoading ?? false,
  isLeftNavOpen: state?.nav?.isLeftNavOpen ?? false,
  selectedRoute: state?.nav?.selectedRoute ?? null,
  srOrders: state?.stat?.srOrders ?? [],
  selectedRouteStatsType: state?.stat?.selectedRouteStatsType ?? '',
  selectedSr: state?.stat?.selectedSr ?? null,
  srVisitedOutlets: state?.stat?.srVisitedOutlets ?? [],
  srPositionData: state?.socket?.srPositionData ?? null,
  srOrderDelayData: state?.socket?.srOrderDelayData ?? null,
  selectedTown: state?.nav?.selectedTown ?? null,
  traceUsers: state?.trace?.traceUsers ?? null,
  outlets: state?.nav?.outlets ?? null,
  isTraceOn: state?.trace?.isTraceOn ?? false
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(UnileverDashboard))
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Drawer } from '@mui/material'
import LeftNavHeader from './LeftNavHeader'
import LeftNavBody from './LeftNavBody'

// Import Actions & Methods
import { setIsLeftNavOpen } from '../../redux/actions/navActions'

class LeftNav extends React.PureComponent {
  // On Close
  _onClose = () => {
    const { dispatch } = this.props
    dispatch( setIsLeftNavOpen(false) )
  }

  render() {
    const { drawerProps, isLeftNavOpen } = this.props

    return (
      <Drawer
        anchor='left'
        hideBackdrop={ true }
        onClose={ this._onClose }
        open={ isLeftNavOpen }
        variant='persistent'
        sx={ drawerStyles }
        { ...drawerProps }
      >
        <LeftNavHeader />
        <LeftNavBody />
      </Drawer>
    )
  }
}

// Styles
const drawerStyles = {
  width: 320,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: 320,
    boxSizing: 'border-box'
  }
}

// Prop Types
LeftNav.propTypes = {
  drawerProps: PropTypes.object,
  isLeftNavOpen: PropTypes.bool
}

LeftNav.defaultProps = {
  drawerProps: {},
  isLeftNavOpen: false
}

const mapStateToProps = state => ({
  isLeftNavOpen: state?.nav?.isLeftNavOpen ?? false
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(LeftNav)
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { OUTLETS } from '../../App.config'

// Import Components
import { Box, Grid } from '@mui/material'
import StyledDateRangePicker from '../common/StyledDateRangePicker'
import StyledSelect from '../common/StyledSelect'
import StyledMultiSelect from '../common/StyledMultiSelect'
import StyledButton from '../common/StyledButton'
import BarChart from '../common/BarChart'
import ExportMenu from '../common/ExportMenu'

// Import Actions & Methods
import { setSelectedCompareOption1, setSelectedCompareOption2, getOutletCompareStats, getRouteCompareStats, getTownCompareStats, getTerritoryCompareStats, getAreaCompareStats, getRegionCompareStats, getNationCompareStats, setSelectedCompareStats1, setSelectedCompareStats2, setComparisonStartDate, setComparisonEndDate, setSelectedComparisonBrand, setSelectedComparisonSku, setSelectedStatsComparisonOptions, setSelectedStatsComparisonFieldOptions, setStatsComparisonData } from '../../redux/actions/statActions'
import { getCommonKeys, exportObjectsAsCsv, exportObjectsAsXlsx } from '../../utils/utils'

class Comparisons extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { dispatch, selectedNation, selectedRegion, selectedArea, selectedTerritory, selectedTown, selectedRoute, comparisonStartDate, comparisonEndDate, selectedComparisonBrand, selectedComparisonSku, selectedStatsComparisonOptions } = this.props

    // If selectedNation Change in Props
    if(prevProps?.selectedNation?.value !== selectedNation?.value && selectedNation?.value) {
      this._resetComparison()
    }

    // If selectedRegion Change in Props
    if(prevProps?.selectedRegion?.value !== selectedRegion?.value && selectedRegion?.value) {
      this._resetComparison()
    }

    // If selectedArea Change in Props
    if(prevProps?.selectedArea?.value !== selectedArea?.value && selectedArea?.value) {
      this._resetComparison()
    }

    // If selectedTerritory Change in Props
    if(prevProps?.selectedTerritory?.value !== selectedTerritory?.value && selectedTerritory?.value) {
      this._resetComparison()
    }

    // If selectedTown Change in Props
    if(prevProps?.selectedTown?.value !== selectedTown?.value && selectedTown?.value) {
      this._resetComparison()
    }

    // If selectedRoute Change in Props
    if(prevProps?.selectedRoute?.value !== selectedRoute?.value && selectedRoute?.value) {
      this._resetComparison()
    }

    // If comparisonStartDate Change in Props
    if(prevProps?.comparisonStartDate !== comparisonStartDate) {
      dispatch( setStatsComparisonData([]) )
    }

    // If comparisonEndDate Change in Props
    if(prevProps?.comparisonEndDate !== comparisonEndDate) {
      dispatch( setStatsComparisonData([]) )
    }

    // If selectedComparisonBrand Change in Props
    if(prevProps?.selectedComparisonBrand?.value !== selectedComparisonBrand?.value && selectedComparisonBrand?.value) {
      dispatch( setStatsComparisonData([]) )
    }

    // If selectedComparisonSku Change in Props
    if(prevProps?.selectedComparisonSku?.value !== selectedComparisonSku?.value && selectedComparisonSku?.value) {
      dispatch( setStatsComparisonData([]) )
    }

    // If selectedStatsComparisonOptions or selectedStatsComparisonFieldOptions Change in Props
    if(prevProps?.selectedStatsComparisonOptions?.length !== selectedStatsComparisonOptions?.length) {
      dispatch( setStatsComparisonData([]) )
    }
  }

  // Filter Outlets List
  _filterOutletsList = outlets => {
    const { datasets, layers, layerData } = this.props

    const outletsDataset = datasets[ OUTLETS.DATA_ID ]
    const outletsLayerIndex = layers.findIndex(l => l.type === 'point' && l.config.dataId === OUTLETS.DATA_ID)
    
    if(!outletsDataset || outletsLayerIndex < 0 || outletsLayerIndex >= layerData.length) {
      return outlets
    }
    
    const outletsLayerData = layerData[ outletsLayerIndex ]?.data ?? []

    const filteredOutlets = []
    outletsLayerData.forEach(o => {
      const outlet = {}
      outletsDataset.fields.forEach(f => {
        outlet[ f.name ] = o?.data[ f.tableFieldIndex-1 ] ?? null
      })
      outlet.value = outlet.id
      outlet.label = outlet?.business_name ?? outlet?.name ?? ''
      filteredOutlets.push(outlet)
    })

    return filteredOutlets
  }

  // Get CompareOptions
  _getCompareOptions = () => {
    const { outlets, routes, towns, territories, areas, regions, nations, selectedRoute, selectedTown, selectedTerritory, selectedArea, selectedRegion, selectedNation } = this.props

    // If Single Route Selected
    if(selectedRoute && (selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None')) {
      return { label: 'Outlet', compareOptions: this._filterOutletsList(outlets) }
    }

    // If Single Town Selected
    if(selectedTown && (selectedTown?.value !== 'All' && selectedTown?.value !== 'None')) {
      return { label: 'Route', compareOptions: routes }
    }

    // If Single Territory Selected
    if(selectedTerritory && (selectedTerritory?.value !== 'All' && selectedTerritory?.value !== 'None')) {
      return { label: 'Town', compareOptions: towns }
    }

    // If Single Area Selected
    if(selectedArea && (selectedArea?.value !== 'All' && selectedArea?.value !== 'None')) {
      return { label: 'Territory', compareOptions: territories }
    }

    // If Single Region Selected
    if(selectedRegion && (selectedRegion?.value !== 'All' && selectedRegion?.value !== 'None')) {
      return { label: 'Area', compareOptions: areas }
    }

    // If Single Nation Selected
    if(selectedNation && (selectedNation?.value !== 'All' && selectedNation?.value !== 'None')) {
      return { label: 'Region', compareOptions: regions }
    }

    // If All Nations Selected
    if(selectedNation && selectedNation?.value === 'All') {
      return { label: 'Nation', compareOptions: nations }
    }

    return []
  }

  // Get CompareFieldOptions
  _getCompareFieldOptions = () => {
    return {
      compareFieldLabel: 'Field',
      compareFieldOptions: [
        { value: 'total_outlets', label: 'Total Outlets' },
        { value: 'visited_outlets', label: 'Visited Outlets' },
        { value: 'productive_outlets', label: 'Productive Outlets' },
        { value: 'non_productive_outlets', label: 'Non Productive Outlets' },
        { value: 'strike_rate', label: 'Strike Rate' },
        { value: 'total_gross_value', label: 'Total Gross Value' },
        { value: 'lines_per_call', label: 'Lines Per Call' }        
      ]
    }
  }

  // On Selected Compare Option 1 Change
  _onSelectedCompareOption1Change = (e, selectedCompareOption1) => {
    const { dispatch } = this.props
    dispatch( setSelectedCompareOption1(selectedCompareOption1) )
  }

  // On Selected Compare Option 2 Change
  _onSelectedCompareOption2Change = (e, selectedCompareOption2) => {
    const { dispatch } = this.props
    dispatch( setSelectedCompareOption2(selectedCompareOption2) )
  }

  // On Selected Stats Comparison Options Change
  _onSelectedStatsComparisonOptionsChange = (e, selectedStatsComparisonOptions) => {
    const { dispatch } = this.props
    dispatch( setSelectedStatsComparisonOptions(selectedStatsComparisonOptions) )
  }

  // On Selected Stats Comparison Field Options Change
  _onSelectedStatsComparisonFieldOptionsChange = (e, selectedStatsComparisonFieldOptions) => {
    const { dispatch } = this.props
    dispatch( setSelectedStatsComparisonFieldOptions(selectedStatsComparisonFieldOptions) )
  }

  // On Form Submit
  _onSubmit = e => {
    const { dispatch, selectedRoute, selectedTown, selectedTerritory, selectedArea, selectedRegion, selectedNation, comparisonStartDate, comparisonEndDate, selectedComparisonBrand, selectedComparisonSku, selectedStatsComparisonOptions } = this.props
    e.preventDefault()

    const brand = selectedComparisonBrand?.label && selectedComparisonBrand?.value !== 'None' && selectedComparisonBrand?.value !== 'All' ? selectedComparisonBrand.label : ''
    const sku = selectedComparisonSku?.label && selectedComparisonSku?.value !== 'None' && selectedComparisonSku?.value !== 'All' ? selectedComparisonSku.label : ''

    // If Single Route Selected, Get Outlet Stats
    if(selectedRoute && (selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None')) {
      dispatch( getOutletCompareStats({
        start_date: comparisonStartDate,
        end_date: comparisonEndDate,
        outlets: selectedStatsComparisonOptions,
        brand,
        sku
      }))
      return
    }

    // If Single Town Selected
    if(selectedTown && (selectedTown?.value !== 'All' && selectedTown?.value !== 'None')) {
      dispatch( getRouteCompareStats({
        start_date: comparisonStartDate,
        end_date: comparisonEndDate,
        routes: selectedStatsComparisonOptions,
        brand,
        sku
      }))
      return
    }

    // If Single Territory Selected
    if(selectedTerritory && (selectedTerritory?.value !== 'All' && selectedTerritory?.value !== 'None')) {
      dispatch( getTownCompareStats({
        start_date: comparisonStartDate,
        end_date: comparisonEndDate,
        towns: selectedStatsComparisonOptions,
        brand,
        sku
      }))
      return
    }

    // If Single Area Selected
    if(selectedArea && (selectedArea?.value !== 'All' && selectedArea?.value !== 'None')) {
      dispatch( getTerritoryCompareStats({
        start_date: comparisonStartDate,
        end_date: comparisonEndDate,
        territories: selectedStatsComparisonOptions,
        brand,
        sku
      }))
      return
    }

    // If Single Region Selected
    if(selectedRegion && (selectedRegion?.value !== 'All' && selectedRegion?.value !== 'None')) {
      dispatch( getAreaCompareStats({
        start_date: comparisonStartDate,
        end_date: comparisonEndDate,
        areas: selectedStatsComparisonOptions,
        brand,
        sku
      }))
      return
    }

    // If Single Nation Selected
    if(selectedNation && (selectedNation?.value !== 'All' && selectedNation?.value !== 'None')) {
      dispatch( getRegionCompareStats({
        start_date: comparisonStartDate,
        end_date: comparisonEndDate,
        regions: selectedStatsComparisonOptions,
        brand,
        sku
      }))
      return
    }

    // If All Nations Selected
    if(selectedNation && selectedNation?.value === 'All') {
      dispatch( getNationCompareStats({
        start_date: comparisonStartDate,
        end_date: comparisonEndDate,
        nations: selectedStatsComparisonOptions,
        brand,
        sku
      }))
      return
    }
  }

  // Reset Comparison
  _resetComparison = () => {
    const { dispatch } = this.props

    dispatch( setSelectedCompareOption1(null) )
    dispatch( setSelectedCompareOption2(null) )
    dispatch( setSelectedCompareStats1({}) )
    dispatch( setSelectedCompareStats2({}) )
    dispatch( setSelectedStatsComparisonOptions([]) )
    dispatch( setSelectedStatsComparisonFieldOptions([]) )
    dispatch( setStatsComparisonData([]) )
  }

  // On Date Range Change
  _onDateRangeChange = dateRange => {
    const { dispatch } = this.props
    dispatch( setComparisonStartDate(dateRange.startDate) )
    dispatch( setComparisonEndDate(dateRange.endDate) )
  }

  // On ComparisonBrand Change
  _onComparisonBrandChange = (e, selectedComparisonBrand) => {
    const { dispatch } = this.props
    dispatch( setSelectedComparisonBrand(selectedComparisonBrand) )
  }

  // On ComparisonSku Change
  _onComparisonSkuChange = (e, selectedComparisonSku) => {
    const { dispatch } = this.props
    dispatch( setSelectedComparisonSku(selectedComparisonSku) )
  }

  // Transform Stats Comparison Data
  _transformStatsComparisonData = (selectedStatsComparisonOptions, statsComparisonData, selectedStatsComparisonFieldOptions) => {
    // Get Common Compare Keys Among Stats Data Collection. Filter Datasets Fields to show only selected comparison fields
    const commonKeys = getCommonKeys(statsComparisonData).filter(k => selectedStatsComparisonFieldOptions.find(scf => scf.value === 'All') || selectedStatsComparisonFieldOptions.find(scf => scf.value === k))

    // Transform Stats Comparison Data Into Chart Prop Format
    const transformedStatsComparisonData = {
      labels: commonKeys.map(l => l.split('_').map(v => v.replace(v[0], v[0].toUpperCase())).join(' ')),
      datasets: selectedStatsComparisonOptions?.map((o, i) => {
        const comparisonData = {}
        Object.keys(statsComparisonData[i] ?? {}).forEach(k => {
          if(commonKeys.includes(k)) {
            comparisonData[k] = statsComparisonData[i][k]
          }
        })

        return {
          label: o?.label ?? 'N/A',
          data: i < statsComparisonData?.length ? Object.values(comparisonData) : []
        }
      })
    }

    return transformedStatsComparisonData
  }

  // On CSV Export
  _onCsvExport = () => {
    const { selectedStatsComparisonOptions, statsComparisonData } = this.props
    const fileName = 'stats_comparison'

    const transformedStats = this._transformComparisonStatsExport(selectedStatsComparisonOptions, statsComparisonData)

    // Export CSV
    exportObjectsAsCsv(transformedStats, fileName)
  }

  // On XLSX Export
  _onXlsxExport = () => {
    const { selectedStatsComparisonOptions, statsComparisonData } = this.props
    const fileName = 'stats_comparison'

    const transformedStats = this._transformComparisonStatsExport(selectedStatsComparisonOptions, statsComparisonData)

    // Export XLSX
    exportObjectsAsXlsx(transformedStats, fileName)
  }


  // Transform Stats Data To Exportable Format
  _transformComparisonStatsExport = (selectedStatsComparisonOptions, statsComparisonData) => {
    const transformedData = statsComparisonData.map((c, i) => {
      const rowObject = { name: selectedStatsComparisonOptions[ i ]?.label ?? 'N/A', ...c }
      return rowObject
    })

    return transformedData
  }

  render() {
    const { comparisonStartDate, comparisonEndDate, brands, selectedComparisonBrand, sku, selectedComparisonSku, selectedStatsComparisonOptions, selectedStatsComparisonFieldOptions, statsComparisonData } = this.props
    const { label, compareOptions } = this._getCompareOptions()
    const { compareFieldLabel, compareFieldOptions } = this._getCompareFieldOptions()
    const _transformedStatsComparisonData = this._transformStatsComparisonData(selectedStatsComparisonOptions, statsComparisonData, selectedStatsComparisonFieldOptions)

    // Filter SKU Options Based on selectedComparisonBrand
    const filteredSku = sku?.filter(s => selectedComparisonBrand.label === 'All' || s.brand === selectedComparisonBrand.label) ?? []

    return (
      <Box sx={{ ...containerStyles, maxHeight: `${ document.querySelector('.right-panel-body')?.clientHeight - 32 }px` }}>
        <Box sx={ formContainerStyles }>
          <form onSubmit={ this._onSubmit } style={{ width: '100%' }}>
            <Grid container={ true } spacing={ 1 }>
              <Grid item={ true } xs={ 12 }>
                <StyledDateRangePicker
                  value={[ comparisonStartDate, comparisonEndDate ]}
                  onChange={ this._onDateRangeChange }
                />
              </Grid>
              <Grid item={ true } xs={ 12 }>
                <StyledSelect
                  label='Select Brand'
                  name='select-comparison-brand'
                  value={ selectedComparisonBrand?.value ?? '' }
                  onChange={ this._onComparisonBrandChange }
                  options={ brands }
                  noneOption={ false }
                />
              </Grid>
              {/* <Grid item={ true } xs={ 12 }>
                <StyledSelect
                  label='Select SKU'
                  name='select-comparison-sku'
                  value={ selectedComparisonSku?.value ?? '' }
                  onChange={ this._onComparisonSkuChange }
                  options={ filteredSku }
                  noneOption={ false }
                />
              </Grid> */}
              <Grid item={ true } xs={ 12 }>
                <StyledMultiSelect
                  label={ label }
                  value={
                    compareOptions
                      ?.filter(o => selectedStatsComparisonOptions.find(c => c.value === o.value && c.label === o.label))
                      ?.map(d => d.value) ?? []
                  }
                  onChange={ this._onSelectedStatsComparisonOptionsChange }
                  options={ compareOptions }
                  noneOption={ false }
                  allOption={ false }
                />
              </Grid>
              <Grid item={ true } xs={ 12 }>
                <StyledMultiSelect
                  label={ compareFieldLabel }
                  value={
                    compareFieldOptions
                      ?.filter(o => selectedStatsComparisonFieldOptions.find(c => c.value === o.value))
                      ?.map(d => d.value) ?? []
                  }
                  onChange={ this._onSelectedStatsComparisonFieldOptionsChange }
                  options={ compareFieldOptions }
                  noneOption={ false }
                  allOption={ false }
                />
              </Grid>
              <Grid item={ true } xs={ 12 }>
                <StyledButton
                  type='submit'
                  disabled={ !selectedStatsComparisonOptions?.length }
                >
                  { 'Compare' }
                </StyledButton>
              </Grid>
              
              <Grid item={ true } xs={ 12 } sx={{ mt: 'auto', mb: 'auto' }}>
                <Box width='100%' display='flex' justifyContent='flex-end'>
                  <ExportMenu
                    buttonId='comparison-export-button'
                    buttonText='Export Comparison'
                    menuId='comparison-export-menu'
                    disabled={ statsComparisonData?.length ? false : true }
                    onExportCsv={ this._onCsvExport }
                    onExportXlsx={ this._onXlsxExport }
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>

        { (statsComparisonData?.length && Object.keys(statsComparisonData[0])?.length) ?
          (
            <React.Fragment>
              <Box sx={{ boxSizing: 'border-box', position: 'relative', margin: 0, padding: 0 }}>
                <BarChart data={ _transformedStatsComparisonData } style={{ minHeight: '320px' }} />
              </Box>
            </React.Fragment>
          )
          :
          (
            null
          )
        }
      </Box>
    )
  }
}

// Styles
const containerStyles = {
  padding: '1rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  overflow: 'auto'
}

const formContainerStyles = {
  marginBottom: '0.5rem',
  width: '100%'
}

// Prop Types
Comparisons.propTypes = {
  outlets: PropTypes.array,
  routes: PropTypes.array,
  towns: PropTypes.array,
  territories: PropTypes.array,
  areas: PropTypes.array,
  regions: PropTypes.array,
  nations: PropTypes.array,
  selectedRoute: PropTypes.object,
  selectedTown: PropTypes.object,
  selectedTerritory: PropTypes.object,
  selectedArea: PropTypes.object,
  selectedRegion: PropTypes.object,
  selectedNation: PropTypes.object,
  selectedCompareOption1: PropTypes.object,
  selectedCompareOption2: PropTypes.object,
  comparisonStartDate: PropTypes.string,
  comparisonEndDate: PropTypes.string,
  brands: PropTypes.array,
  selectedComparisonBrand: PropTypes.object,
  sku: PropTypes.array,
  selectedComparisonSku: PropTypes.object,
  selectedCompareStats1: PropTypes.object,
  selectedCompareStats2: PropTypes.object,
  datasets: PropTypes.object,
  layers: PropTypes.array,
  layerData: PropTypes.array,
  selectedStatsComparisonOptions: PropTypes.array,
  selectedStatsComparisonFieldOptions: PropTypes.array,
  statsComparisonData: PropTypes.array
}

Comparisons.defaultProps = {
  outlets: [],
  routes: [],
  towns: [],
  territories: [],
  areas: [],
  regions: [],
  nations: [],
  selectedRoute: null,
  selectedTown: null,
  selectedTerritory: null,
  selectedArea: null,
  selectedRegion: null,
  selectedNation: null,
  selectedCompareOption1: null,
  selectedCompareOption2: null,
  comparisonStartDate: '',
  comparisonEndDate: '',
  brands: [],
  selectedComparisonBrand: null,
  sku: [],
  selectedComparisonSku: null,
  selectedCompareStats1: {},
  selectedCompareStats2: {},
  datasets: {},
  layers: [],
  layerData: [],
  selectedStatsComparisonOptions: [],
  selectedStatsComparisonFieldOptions: [],
  statsComparisonData: []
}

const mapStateToProps = state => ({
  outlets: state?.nav?.outlets ?? [],
  routes: state?.nav?.routes ?? [],
  towns: state?.nav?.towns ?? [],
  territories: state?.nav?.territories ?? [],
  areas: state?.nav?.areas ?? [],
  regions: state?.nav?.regions ?? [],
  nations: state?.nav?.nations ?? [],
  selectedRoute: state?.nav?.selectedRoute ?? null,
  selectedTown: state?.nav?.selectedTown ?? null,
  selectedTerritory: state?.nav?.selectedTerritory ?? null,
  selectedArea: state?.nav?.selectedArea ?? null,
  selectedRegion: state?.nav?.selectedRegion ?? null,
  selectedNation: state?.nav?.selectedNation ?? null,
  selectedCompareOption1: state?.stat?.selectedCompareOption1 ?? null,
  selectedCompareOption2: state?.stat?.selectedCompareOption2 ?? null,
  comparisonStartDate: state?.stat?.comparisonStartDate ?? '',
  comparisonEndDate: state?.stat?.comparisonEndDate ?? '',
  brands: state?.stat?.brands ?? [],
  selectedComparisonBrand: state?.stat?.selectedComparisonBrand ?? null,
  sku: state?.stat?.sku ?? [],
  selectedComparisonSku: state?.stat?.selectedComparisonSku ?? null,
  selectedCompareStats1: state?.stat?.selectedCompareStats1 ?? {},
  selectedCompareStats2: state?.stat?.selectedCompareStats2 ?? {},
  datasets: state?.keplerGl?.map?.visState?.datasets ?? {},
  layers: state?.keplerGl?.map?.visState?.layers ?? [],
  layerData: state?.keplerGl?.map?.visState?.layerData ?? [],
  selectedStatsComparisonOptions: state?.stat?.selectedStatsComparisonOptions ?? [],
  selectedStatsComparisonFieldOptions: state?.stat?.selectedStatsComparisonFieldOptions ?? [],
  statsComparisonData: state?.stat?.statsComparisonData ?? []
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(Comparisons)
import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

// Import Components
import { Box, TextField, ClickAwayListener, IconButton } from '@mui/material'
import { DateTimePicker } from '@mui/lab'
import { ArrowRightAlt, Event } from '@mui/icons-material'

class StyledDateRangePicker extends React.PureComponent {
  state = {
    isStartOpen: false,
    isEndOpen: false
  }

  // On Start Date Picker Open
  _onStartDatePickerOpen = () => {
    this.setState({ isStartOpen: true, isEndOpen: false })
  }

  // On Start Date Picker Close
  _onStartDatePickerClose = () => {
    this.setState({ isStartOpen: false })
  }

  // On End Date Picker Open
  _onEndDatePickerOpen = () => {
    this.setState({ isStartOpen: false, isEndOpen: true })
  }

  // On End Date Picker Close
  _onEndDatePickerClose = () => {
    this.setState({ isEndOpen: false })
  }

  // On All Pickers Close
  _onAllPickersClose = () => {
    this.setState({ isStartOpen: false, isEndOpen: false })
  }

  // On Start Date Change
  _onStartDateChange = date => {
    const { onChange, value } = this.props

    // Get start date from date picker & end date from `value` prop
    const startDate = date?.$d && dayjs( new Date(date?.$d) ).format('YYYY-MM-DD HH:mm:ss')
    const endDate = value?.length && value?.length > 1 ? value[1] : ''

    onChange({ startDate, endDate })
  }

  // On End Date Change
  _onEndDateChange = date => {
    const { onChange, value } = this.props

    // Get start date from `value` prop & end date from date picker
    const startDate = value?.length ? value[0] : ''
    const endDate = date?.$d && dayjs( new Date(date?.$d) ).format('YYYY-MM-DD HH:mm:ss')

    onChange({ startDate, endDate })
  }

  render() {
    const { value, disabled } = this.props
    const { isStartOpen, isEndOpen } = this.state

    return (
      <ClickAwayListener onClickAway={ this._onAllPickersClose }>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', my: '4px', mt: '6px' }}>
          <DateTimePicker
            open={ isStartOpen }
            label='Start Date'
            value={ value?.length ? value[0] : '' }
            onChange={ this._onStartDateChange }
            disableMaskedInput={ true }
            inputFormat={ `DD-MM-'YY HH:mm` }
            showTodayButton={ true }
            disabled={ disabled }
            maxDate={ dayjs(value?.length && value?.length > 1 ? value[1] : '') }
            disableCloseOnSelect={ false }
            onAccept={ this._onStartDatePickerClose }
            allowSameDateSelection={ true }
            renderInput={ params =>
              <TextField
                { ...params }
                size='small'
                fullWidth={ true }
                InputProps={{
                  notched: false,
                  endAdornment: 
                    <IconButton size='small' edge='end' onClick={ this._onStartDatePickerOpen }>
                      <Event fontSize='small' />
                    </IconButton>,
                  sx: {
                    padding: '0.25rem',
                    maxHeight: '28px',
                    fontSize: '0.7rem'
                  }
                }}
                InputLabelProps={{
                  sx: {
                    '&.MuiInputLabel-root': {
                      mt: '-2px',
                      fontSize: '0.8rem'
                    },
                    '&.MuiInputLabel-shrink': {
                      mt: '2px',
                      ml: '-4px',
                      px: '8px',
                      fontSize: '0.8rem',
                      background: '#fff'
                    }
                  }
                }}
              />
            }
          />

          <ArrowRightAlt fontSize='small' />

          <DateTimePicker
            open={ isEndOpen }
            label='End Date'
            value={ value?.length && value?.length > 1 ? value[1] : '' }
            onChange={ this._onEndDateChange }
            disableMaskedInput={ true }
            inputFormat={ `DD-MM-'YY HH:mm` }
            showTodayButton={ true }
            disabled={ disabled }
            minDate={ dayjs(value?.length ? value[0] : '') }
            maxDate={ dayjs() }
            disableCloseOnSelect={ false }
            onAccept={ this._onEndDatePickerClose }
            allowSameDateSelection={ true }
            renderInput={ params =>
              <TextField
                { ...params }
                size='small'
                fullWidth={ true }
                InputProps={{
                  notched: false,
                  endAdornment:
                    <IconButton size='small' edge='end' onClick={ this._onEndDatePickerOpen }>
                      <Event fontSize='small' />
                    </IconButton>,
                  sx: {
                    padding: '0.25rem',
                    maxHeight: '28px',
                    fontSize: '0.7rem'
                  }
                }}
                InputLabelProps={{
                  sx: {
                    '&.MuiInputLabel-root': {
                      mt: '-2px',
                      fontSize: '0.8rem'
                    },
                    '&.MuiInputLabel-shrink': {
                      mt: '2px',
                      ml: '-4px',
                      px: '8px',
                      fontSize: '0.8rem',
                      background: '#fff'
                    }
                  }
                }}
              />
            }
          />
        </Box>
      </ClickAwayListener>
    )
  }
}

// Prop Types
StyledDateRangePicker.propTypes = {
  value: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}

StyledDateRangePicker.defaultProps = {
  value: [],
  disabled: false,
  onChange: () => null
}

export default StyledDateRangePicker
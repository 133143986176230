import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box, Typography, Slider } from '@mui/material'

// Import Actions & Methods
import { setSelectedStrikeRate, filterNations, filterRegions, filterAreas, filterTerritories, filterTowns, filterRoutes } from '../../redux/actions/mapActions'

const FilterByStrikeRate = ({ dispatch, selectedStrikeRate, selectedRoute, selectedTown, selectedTerritory, selectedArea, selectedRegion, selectedNation }) => {
  // On Strike Rate Change
  const _onStrikeRateChange = (e, selectedStrikeRate) => {
    dispatch( setSelectedStrikeRate(selectedStrikeRate) )
  }

  // On Strike Rate Change Committed
  const _onStrikeRateChangeCommitted = (e, selectedStrikeRate) => {
    // If All Routes Selected
    if(selectedRoute?.value === 'All') {
      // Filter Routes by Strike Rate
      dispatch(
        filterRoutes({
          name: 'strike_rate',
          value: selectedStrikeRate
        })
      )
      
      return
    }

    // If All Towns Selected
    if(selectedTown?.value === 'All') {
      // Filter Towns by Strike Rate
      dispatch(
        filterTowns(
          {
            name: 'strike_rate',
            value: selectedStrikeRate
          },
          { persistDistributionHouse: false }
        )
      )

      return
    }

    // If All Territories Selected
    if(selectedTerritory?.value === 'All') {
      // Filter Territories by Strike Rate
      dispatch(
        filterTerritories({
          name: 'strike_rate',
          value: selectedStrikeRate
        })
      )

      return
    }

    // If All Areas Selected
    if(selectedArea?.value === 'All') {
      // Filter Areas by Strike Rate
      dispatch(
        filterAreas({
          name: 'strike_rate',
          value: selectedStrikeRate
        })
      )

      return
    }

    // If All Regions Selected
    if(selectedRegion?.value === 'All') {
      // Filter Regions by Strike Rate
      dispatch(
        filterRegions({
          name: 'strike_rate',
          value: selectedStrikeRate
        })
      )

      return
    }

    // If All Nations Selected
    if(selectedNation?.value === 'All') {
      // Filter Nations by Strike Rate
      dispatch(
        filterNations({
          name: 'strike_rate',
          value: selectedStrikeRate
        })
      )

      return
    }
  }

  return (
    <Box paddingX='0.8rem'>
      <Typography
        align='left'
        noWrap={ true }
        variant='caption'
        color='textSecondary'
      >
        { 'by Strike Rate' }
      </Typography>
      <Slider
        name='strike-rate-range'
        size='small'
        marks={[
          { value: 0, label: 0 },
          { value: 20, label: 20 },
          { value: 40, label: 40 },
          { value: 60, label: 60 },
          { value: 80, label: 80 },
          { value: 100, label: 100 }
        ]}
        max={ 100 }
        min={ 0 }
        step={ 10 }
        value={ selectedStrikeRate }
        valueLabelDisplay='auto'
        onChange={ _onStrikeRateChange }
        onChangeCommitted={ _onStrikeRateChangeCommitted }
        sx={{
          margin: 0,
          marginTop: '-0.5rem',
          '& .MuiSlider-markLabel': {
            marginTop: '-0.8rem'
          }
        }}
      />
    </Box>
  )
}

// Prop Types
FilterByStrikeRate.propTypes = {
  selectedStrikeRate: PropTypes.array,
  selectedRoute: PropTypes.object,
  selectedTown: PropTypes.object,
  selectedTerritory: PropTypes.object,
  selectedArea: PropTypes.object,
  selectedRegion: PropTypes.object,
  selectedNation: PropTypes.object,
  dispatch: PropTypes.func
}

FilterByStrikeRate.defaultProps = {
  selectedStrikeRate: [ 0, 100 ],
  selectedRoute: null,
  selectedTown: null,
  selectedTerritory: null,
  selectedArea: null,
  selectedRegion: null,
  selectedNation: null,
  dispatch: () => null
}

const mapStateToProps = state => ({
  selectedStrikeRate: state?.map?.selectedStrikeRate ?? [ 0, 100 ],
  selectedRoute: state?.nav?.selectedRoute ?? null,
  selectedTown: state?.nav?.selectedTown ?? null,
  selectedTerritory: state?.nav?.selectedTerritory ?? null,
  selectedArea: state?.nav?.selectedArea ?? null,
  selectedRegion: state?.nav?.selectedRegion ?? null,
  selectedNation: state?.nav?.selectedNation ?? null
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(FilterByStrikeRate)
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box, Typography, Slider } from '@mui/material'

// Import Actions & Methods
import { filterOutlets } from '../../redux/actions/mapActions'

const FilterByTargetAchievedPercentile = ({ dispatch, selectedRoute, selectedTargetAchievedPercentile, onTargetAchievedPercentileChange }) => {
  // On Target Achieved Percentile Change Committed
  const _onTargetAchievedPercentileChangeCommitted = (e, selectedTargetAchievedPercentile) => {
    // If Single Route Selected
    if(selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None' && selectedRoute?.value) {
      // Filter Outlets by Target Achieved Percentile
      dispatch(
        filterOutlets({
          name: 'target_achieved_percentile',
          value: selectedTargetAchievedPercentile
        })
      )
    }
  }

  return (
    <Box paddingX='0.8rem'>
      <Typography
        align='left'
        noWrap={ true }
        variant='caption'
        color='textSecondary'
      >
        { 'by Target Achieved Percentile' }
      </Typography>
      <Slider
        name='target-achieved-percentile-range'
        size='small'
        marks={[
          { value: 0, label: 0 },
          { value: 20, label: 20 },
          { value: 40, label: 40 },
          { value: 60, label: 60 },
          { value: 80, label: 80 },
          { value: 100, label: 100 }
        ]}
        max={ 100 }
        min={ 0 }
        step={ 10 }
        value={ selectedTargetAchievedPercentile }
        valueLabelDisplay='auto'
        onChange={ onTargetAchievedPercentileChange }
        onChangeCommitted={ _onTargetAchievedPercentileChangeCommitted }
        sx={{
          margin: 0,
          marginTop: '-0.5rem',
          '& .MuiSlider-markLabel': {
            marginTop: '-0.8rem'
          }
        }}
      />
    </Box>
  )
}

// Prop Types
FilterByTargetAchievedPercentile.propTypes = {
  selectedRoute: PropTypes.object,
  selectedTargetAchievedPercentile: PropTypes.array,
  onTargetAchievedPercentileChange: PropTypes.func,
  dispatch: PropTypes.func
}

FilterByTargetAchievedPercentile.defaultProps = {
  selectedRoute: null,
  selectedTargetAchievedPercentile: [ -100, 100 ],
  onTargetAchievedPercentileChange: () => null,
  dispatch: () => null
}

const mapStateToProps = state => ({
  selectedRoute: state?.nav?.selectedRoute ?? null
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(FilterByTargetAchievedPercentile)